import React from 'react'
import { use, api } from 'xadmin'
import { DeleteOutlined, } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip, message } from 'antd'

const ActionBanStart = ({ id, name, status, url }) => {
  const { modelDispatch } = use('model')
  const statusStr = (status ? '禁用' : '启用')
  const ProjectMes = status ? '禁用后该项目无法登录，数据不会丢失，确认禁用该项目？' :
    '启用后项目历史数据可以正常使用，用户可继续登录项目，确认启用该项目？'
  const userMes = status ? '禁用后租户及租户创建的项目均不可使用，数据不会丢失，确认禁用该租户？' :
    '启用该租户后，租户及其项目可正常登录，确认启用该租户？'
  const onClick = () => {
    if (!id) return
    api({ name: url + '/' }).fetch(id + '/status', {
      method: 'PATCH',
      body: JSON.stringify({ status: !status })
    })
      .then(({ status: resStatus }) => {
        if (resStatus === 200) {
          message.success(name + statusStr + '成功')
          modelDispatch({ type: 'GET_ITEMS' })
        }
      })
  }

  return <Popconfirm key="action-delete" title={url == 'project' ? ProjectMes : userMes} overlayStyle={{ width: 260 }}
    onConfirm={onClick} okText={statusStr} cancelText='取消'>
    <Tooltip placement="top" title={statusStr}>
      <Button >{statusStr}</Button>
    </Tooltip>
  </Popconfirm>
}

const ActionDelete = (props) => {
  const { mes } = props
  const { canDelete } = use('model.permission', props)
  const { deleteItem } = use('model.delete', props)
  if (!canDelete) return null

  return (
    <Popconfirm key="action-delete" title={mes} overlayStyle={{ width: 260 }}
      onConfirm={() => deleteItem()} okText='确定删除' cancelText='取消'>
      <Tooltip placement="top" title='删除'>
        <Button key="action-delete" size="small" className="model-list-action" type="danger">
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  )
}

export { ActionBanStart, ActionDelete }