import React from 'react';
import { api } from 'xadmin'
import { Column, Line } from '@ant-design/charts';
import moment from 'moment'
import { message } from 'antd'

const Echarts = props => {
  const { type } = props
  let [data, setData] = React.useState([])
  let [userData, setUserData] = React.useState([])

  let config = {
    data: type == 'project' ? data : userData,
    xField: 'createTime',
    yField: 'value',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      createTime: { alias: '时间' },
      value: { alias: '数量' },
    },
  }

  const dateArr = i => {
    return moment(new Date().getTime() - 86400000 * i).format('YYYY-MM-DD')
  }

  React.useEffect(() => {
    let time = moment(new Date()).subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
    let dateGroup = []
    for (let i = 0; i < 7; i++) { dateGroup.push(dateArr(i)) }
    const date_reserve = dateGroup.reverse()

    let stringQuery = {
      groupBy: {
        date: {
          '$dateToString': {
            format: '%Y-%m-%d',
            date: '$createTime'
          }
        }
      },
      groupFields: {
        count: {
          '$sum': 1
        }
      },
      filter: {
        createTime: {
          '$gte': time
        }
      }
    }

    let filter_string = encodeURIComponent(JSON.stringify(stringQuery))
    api({ name: '' }).fetch(`project?query=${filter_string}`, {})
      .then(({ json }) => {
        if (!json || json?.length == 0) return false
        let newJson = date_reserve && date_reserve.length && date_reserve.map(item => {
          let index = json.findIndex(val => {
            return val?.id?.date == item
          })
          if (index > -1) {
            return {
              createTime: json[index].id.date,
              value: json[index].count
            }
          } else {
            return {
              createTime: item,
              value: 0
            }
          }
        })
        setData(newJson)
      })
      .catch(err => {
        err?.message && message.error(err?.message)
      })


    api({ name: '' }).fetch(`user?query=${filter_string}`, {})
      .then(({ json }) => {
        if (!json || json?.length == 0) return false
        let newJson = date_reserve && date_reserve.length && date_reserve.map(item => {
          let index = json.findIndex(val => {
            return val?.id?.date == item
          })
          if (index > -1) {
            return {
              createTime: json[index].id.date,
              value: json[index].count
            }
          } else {
            return {
              createTime: item,
              value: 0
            }
          }
        })
        setUserData(newJson)
      })
      .catch(err => {
        err?.message && message.error(err.message)
      })

  }, [])

  return <>{type == 'project' ? <Line {...config} /> : <Column {...config} />}</>
}
export default Echarts
