import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Col, Row } from 'antd';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { app, api, use } from 'xadmin';
import ViewPage from '../schemepreview'
import '../styles/view.css'
import { Model } from 'xadmin-model/lib/base';
const { Meta } = Card
const ProjectModal = (props) => {
  const { input: { onChange, value} } = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [ state, setState ] = useState(false)
  const [ data, setData ] = useState()
  const [ id, setId ] = useState()
  const [ preViewId, setPreViewId ] = useState()
  const isEdit = window.location.hash.includes('edit')

  const showModal = () => {
    setIsModalVisible(true);

  };

  const handleOk = () => {
    onChange(id? id+'':id)
    !id && setData(null)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setId(value)
  };
  const url = ''
  const activeStyle = {
    position: 'absolute',
    width: '105%',
    height: '105%',
    borderRadius: 5,
    top: -6,
    left: -8,
    background: 'rgba(24,144,255,0.5)'
  }

  // const arr = [1, 2, 3, 4]
  const [ arr, setArr ] = useState([1,2,3,4])
  useEffect(()=>{
    api({name:'templates'}).fetch(``,{}).then(({json})=>{
      setArr(json)
      value && json.map(item=>{
        item.id == value && setData(item)
      })
    })
  },[])
  
  useEffect(()=>{
    arr.map(item=>{
      item.id == value && setData(item)
    })
  },[value])
  const onInstall = (id)=>{
    setId(id)
  }
  const Shadow = (props) =>{
    return (state?<div className="hover-model" style={props.style}>   
    {/* app.go('app/schemepreview?id='+ props.schemaId) */}
    <Button className="hover-button" style={{marginTop: !props.style?'100px': '60px'}} onClick={()=> {setVisible(true); setPreViewId(props.schemaId)}}>预览</Button><br/>
    {!props.style ? ((id != props.schemaId && !isEdit) ? <Button className="hover-button" style={{marginTop: '10px'}} onClick={()=>onInstall(props.schemaId)}>使用</Button>:
      <Button className="hover-button" style={{marginTop: '10px'}} onClick={()=>onInstall()}>取消使用</Button>): null}
    <Modal visible={visible} width={"calc(100%)"} style={{ top: 0 }} footer={null} onCancel={()=>setVisible(false)} keyboard={false} mask={false} maskClosable={false}>
      <div style={{width: 'calc(100%)',height:'calc(100vh)'}}><ViewPage schemaId={preViewId} setVisible={setVisible} onInstall={onInstall}/></div>
    </Modal>
  </div>:null)
  }

  return (
    <>
      {!isEdit?<Button type="primary" onClick={showModal}>
        解决方案市场
      </Button>:null}
      <Modal
        title="方案市场"
        width="87%"
        visible={isModalVisible}
        onOk={handleOk}
        // bodyStyle={{ backgroundColor: '#f0f2f5' }}
        onCancel={handleCancel}>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            {arr.map(item => {
              return (
                <Col span={6}>
                  <div onMouseOver={(e)=>{ setState(item)}} onMouseLeave={()=>{setState(false)}}>
                    {state == item ? <Shadow schemaId={item.id}/>: null}
                    <Card style={{ height: 320, marginBottom: 15 }}
                      hoverable title={<div>{item.name}<div style={{float: 'right', color: 'green'}}>{id == item.id? <CheckCircleOutlined />:null}</div></div>}
                      onClick={() =>{ setSelected(item);}}
                      cover={<img alt="example" style={{ height: 150 }} src={'http://airiot.link:1337'+ item?.card_display?.cover?.url} />}
                    >
                      <div style={item == selected ? activeStyle : {}}></div>

                      <Meta title={item?.developer} description={item?.card_display?.intro} />
                    </Card>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
      </Modal>
      {!!data?
      <div onMouseOver={(e)=>{ setState(data)}} onMouseLeave={()=>{setState(false)}} >
        <Shadow schemaId={data.id} style={{ height: 150, width: 500, marginTop: 58}}/>
        <Card style={{ height: 320, width: 500, marginTop: 10 }}
              title={<div>{data.name} {!isEdit? <DeleteOutlined style={{float:'right',marginTop: 6}} onClick={()=>{setId();onChange(null);setData(null)}}/>:null }</div>}
              // onClick={() =>{ setSelected(item);}} //
              cover={<img alt="example" style={{ height: 150 }} src={'http://airiot.link:1337' + data?.card_display?.cover?.url} />}
            >
              <div style={data == selected ? activeStyle : {}}></div>

              <Meta title={data?.developer} description={data?.card_display?.intro} />
            </Card></div>: null}
            
    </>
  );
}

export default ProjectModal