import React from 'react'
import { SketchPicker } from 'react-color'
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button, Popover } from 'antd';

const getTrans = (color) => {
  let result = 100
  if (color && !color.includes('#')) {
    let str = color.split(',')[3]
    result = Math.round(parseFloat(str?.substring(0, str.length - 1)) * 100)
  }
  return result
}

const ColorPickerField = ({ input }) => {
  const [color, setColor] = React.useState(input.value)
  const [trans, setTrans] = React.useState(!input.value.includes('#') && input.value !== "none" ? getTrans(input.value) : 100)

  React.useEffect(props => {
    setColor(input.value)
    setTrans(!input.value.includes('#') && input.value !== "none" ? getTrans(input.value) : 100)
  }, [input.value])

  const handleChange = (color) => {
    if (!color) {
      input.onChange(color)
      setColor(color)
    } else {
      const rgba = `rgba(${Object.values(color.rgb)})`
      input.onChange(rgba)
      setTrans(Math.round(color.rgb.a * 100))
      setColor(rgba)
    }
  }

  return (
    <div >
      <Row>
        <Popover overlayClassName="color-picker-swatch"
          content={<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}  >
            <SketchPicker color={color || 'rgb(170,170,170)'} onChange={handleChange} />
          </div>} trigger="click">
          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 1, offset: 0 }}>
            <div className="dashborad-color-picker-swatch" style={{ width: 100, height: 30, backgroundColor: color, cursor: "pointer" }} >
              {color ? '' : <Button type="dashed" block>选择颜色</Button>}
            </div>
          </Col>
        </Popover>
        <Col lg={{ span: 1, offset: 2 }}>
          {color && <DeleteOutlined style={{ marginTop: 8 }} onClick={() => { handleChange(null) }} />}
        </Col>
      </Row>
    </div>
  )
}

export default ColorPickerField