import React from 'react'
import nodata from './../icon/noData.png'

const NoData = props => {

  return (
    <>
      <div style={props.style ? props.style : {}}>
        <div style={{ textAlign: 'center' }}>
          <img draggable="false" style={{ height: 100 }} src={nodata} />
        </div>
        <div style={{ marginTop: '.4rem', textAlign: 'center', lineHeight: 1.5715 }}>
          {props.description ? props.description : '暂无数据'}
        </div>
      </div>
    </>
  )
}

export default NoData
