import React from 'react'
import { Page } from 'xadmin-ui'
import { SchemaForm } from 'xadmin-form';
import { api, app } from 'xadmin';
import { Card, Form, message } from 'antd';

const UserProfileSchema = {
  type: 'object',
  properties: {
    username: {
      title: '用户名',
      type: 'string'
    },
    password: {
      title: '密码',
      type: 'string'
    }
  },
  form: [
    '*',
    {
      key: 'password', attrs: { type: 'password' },
      validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
    }
  ]
}

const DefaultLayout = props => {
  const { children, invalid, handleSubmit, submitting } = props;
  const { _t } = app.context;
  return (
    <Form onSubmit={handleSubmit}>
      <Card style={{ marginTop: 0, borderTop: 'none', color: '#333' }}>
        {children}
      </Card>
    </Form>
  );
}

const UserProfile = props => {
  const [user, setUser] = React.useState()

  React.useEffect(() => {
    api({ name: '' }).fetch('login/current/user', {}).then(({ json }) => {
      setUser({ ...json, username: json?.name })
    }).catch(err => err?.message && message.error(err.message))
  }, [])

  const onSubmit = value => { }
  return (
    <Page title={'个人中心'}>
      <SchemaForm
        formKey='profile'
        schema={UserProfileSchema}
        onSubmit={(value) => onSubmit(value)}
        initialValues={user}
        component={DefaultLayout}
      />
    </Page>
  )
}


export default UserProfile
