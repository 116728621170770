
import React, { useEffect } from 'react'
import { api, use } from 'xadmin'
import { Icon } from 'xadmin-ui'
import { Row, Col, Progress, notification, Button } from 'antd'
import './../styles/license.css'
import { WarningOutlined } from '@ant-design/icons'
import { Pie } from '@ant-design/charts';

const PointCard = () => {
  const data = use('getUserLicense')
  const currentData = use('getCurrentGrant')
  const adminPoint = use('getAdminProjectGrant')
  const _dataPoint = currentData + adminPoint
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close('notLicense')}>
      关闭提醒
    </Button>
  )

  const _data = [
    {
      type: '剩余数据点',
      value: data?.modelNodeCounts - _dataPoint,
    },
    {
      type: '当前数据点',
      value: _dataPoint,
    },
  ];
  const config = {
    appendPadding: 10,
    data: _data,
    angleField: 'value',
    colorField: 'type',
    color: ['#7c9af7', '#a5e3f7'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}',
    },
    tooltip: {
      formatter: (arg)=>{
        return { name: arg.type, value: ((arg.value/data?.modelNodeCounts)*100).toLocaleString() + '%' }
      }
    }
  };

  useEffect(() => {
    let notLicense = localStorage.getItem('notLicense')
    if (!notLicense && data?.remainingTime <= 14) {
      notification.open({
        message: <span><WarningOutlined /> 授权提醒</span>,
        placement: 'bottomRight',
        btn, key: 'notLicense',
        description: '系统授权可用时间不足14天，请及时关注并扩展授权',
        onClick: () => { localStorage.setItem('notLicense', true) },
      })
    }
  }, [])

  const titleSty = {
    fontSize: '16px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    fontWeight: 400,
    color: '#303030',
    lineHeight: '30px'
  }

  return (
    <>
      {
        data?.licenseType == 'platform-spm' ?
          <Row gutter={14}>
            <Col span={24}>
              <div style={{ height: 236, background: '#fff' }}>
                <div style={{ padding: '10px 20px', display: 'flex', lineHeight: '30px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: '30px' }}>
                    <span style={{ ...titleSty, marginLeft: '4px' }}><Icon type="deployment-unit" />数据点</span>
                    <span style={{ ...titleSty, marginLeft: '4px', float: 'right', fontWeight: 900 }}>
                      {_dataPoint + '/' + data?.modelNodeCounts}
                    </span>
                  </div>
                </div>
                <div style={{ height: '1px', background: '#E4ECFD' }}></div>
                <Row style={{ padding: '8px 13px 5px', background: '#fff', boxShadow: '0px 2px 4px 0px rgba(231, 231, 231, 0.5)' }}>
                  <Col span={24}>
                    <div style={{ height: '172px' }}>
                      <Pie {...config} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row > : null
      }
    </>
  )
}

export default PointCard
