import React from 'react'
import { use, config as _c, Block, app, api } from 'xadmin'
import { Layout, Menu, Card, Result, message, Drawer, Empty, List } from 'antd'
import { Icon } from 'xadmin-ui'
import {
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { IsAuthenticated } from 'xadmin-auth'
import MainMenu from '../components/MainMenu'
import MessageList from '../components/MessageList'
import './../styles/layout.css'
import './../styles/project.css'
const { Sider, Footer, Content } = Layout

const ProtectError = ({ error, onClose }) => {
  return (
    <Card>
      <Result
        status="error"
        title="您没有查看该页面权限"
      >
      </Result>
    </Card>
  )
}

const TenementLayout = ({ pathname, onLogout }) => {
  const { user } = use('redux', state => ({ user: state.user }))

  return (
    <Layout.Header className="main-header header-horizontal tenant-layout" style={{
      display: 'flex', height: 64, lineHeight: '64px',
      justifyContent: 'space-between', zIndex: 10,
      backgroundColor: '#4F71FF'
    }}>
      <div className="logo" className="logo-img" style={{ display: 'flex', 'webkitAppRegion': 'drag', alignItems: 'center' }} onClick={() => app.go('/')}>
        {_c('site.logo')}
        {_c('site.title')}
      </div>
      <Menu mode="horizontal" className="protol-header" style={{ width: '70%' }}>
        <Menu.Item key="mail" onClick={() => app.go('/app/model/Project/list')}>
          我的项目
        </Menu.Item>
        <Menu.Item key="app" onClick={() => app.go('/app/templates')}>
          模板市场
        </Menu.Item>
      </Menu>
      <div style={{}}><Icon type="bell" style={{ color: '#fff' }}></Icon></div>
      <Block name="top.right" >
        {
          items => {
            return (
              <Menu mode="horizontal" theme="default" className="protol-header menu-none" style={{ display: 'flex', alignItems: 'center' }}>
                <Menu.SubMenu key="user-dorpdown " className="user-menu"
                  title={<span className="submenu-title-wrapper">
                    <span ><UserOutlined /></span>
                    <span className="nav-text" >{user?.username}</span>
                  </span>}
                >
                  <Menu.Item key="changePassword" onClick={() => app.go('/app/change_current_password')}><KeyOutlined />修改密码</Menu.Item >
                  <Menu.Item key="profile" onClick={() => app.go('/app/user/center')}><SettingOutlined /> 个人中心</Menu.Item>
                  <Menu.Item key="logout" onClick={() => onLogout()}><LogoutOutlined /> 退出系统</Menu.Item>
                </Menu.SubMenu>
              </Menu>
            )
          }
        }
      </Block>
    </Layout.Header>
  )
}

const ClientLayout = ({ pathname, onLogout }) => {
  const { user } = use('redux', state => ({ user: state.user }))
  const [collapseds, setCollapseds] = React.useState(true)
  const [selectedKeys, setSelectedKeys] = React.useState([])

  const onCollapse = (coll) => {
    setCollapseds(coll)
    localStorage.setItem('Menu_collapsed', coll)
  }

  React.useEffect(() => {
    const coll = localStorage.getItem('Menu_collapsed')
    setCollapseds(coll === "false" ? false : true)
  }, [])

  return (
    <Sider
      collapsible
      collapsedWidth={80}
      collapsed={collapseds}
      onCollapse={onCollapse}
      style={!collapseds ? { minHeight: 300, height: '100vh', overflow: 'auto' } : { minHeight: 300 }}
    >
      <div className="logo" style={{ 'webkitAppRegion': 'drag' }} onClick={() => app.go('/')}>
        {_c('site.logo')}
        {_c('site.title')}
      </div >
      <MainMenu name="mainmenu" theme="dark"
        isHome={false}
        setIsHome={() => { }}
        collapseds={collapseds}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={pathname}
      />
      <Block name="top.right" >
        {
          items => {
            return (
              <Menu theme="dark">
                <Menu.SubMenu key="user-dorpdown " className="user-menu"
                  title={<span className="submenu-title-wrapper">
                    <span ><UserOutlined /></span>
                    <span className="nav-text" >{user?.username}</span>
                  </span>}
                >
                  <Menu.Item key="changePassword" onClick={() => app.go('/app/change_current_password')}><KeyOutlined style={{ marginRight: '.5rem' }} />修改密码</Menu.Item >
                  <Menu.Item key="profile" onClick={() => app.go('/app/user/center')}><SettingOutlined style={{ marginRight: '.5rem' }} />个人中心</Menu.Item>
                  <Menu.Item key="logout" onClick={() => onLogout()}><LogoutOutlined style={{ marginRight: '.5rem' }} />退出系统</Menu.Item>
                </Menu.SubMenu>
              </Menu>
            )
          }
        }
      </Block>
    </Sider >
  )
}


const App = IsAuthenticated(props => {
  const pathname = props.location.pathname
  const { settings } = use('settings')
  const { user } = use('redux', state => ({ user: state.user }))


  const onLogout = () => {
    // 退出登录
    api({ name: '' }).fetch('login/exit', {
      method: 'POST'
    }).then(({ json }) => {
      message.success('退出成功')
      localStorage.setItem('user', JSON.stringify(''))
      app.go('/login')
    }
    ).catch(err => { err?.message && message.error(err.message) })
  }

  return (user?.protectUrl && user?.protectUrl != pathname) ? <ProtectError /> : (
    <Layout hasSider style={{ height: "100%", overflow: 'auto' }}>
      {
        user?.isAdmin ? <>
          <ClientLayout pathname={pathname} onLogout={onLogout} />
          <Layout>
            <Content >
              {props.children}
            </Content>
            <Footer style={{ textAlign: 'center', padding: 10 }} >
              {settings?.copyright || ""}
            </Footer>
          </Layout>
        </>
          : <Layout>
            <TenementLayout pathname={pathname} onLogout={onLogout} />
            <Content >
              {props.children}
            </Content>
            <Footer style={{ textAlign: 'center', padding: 10 }} >
              {settings?.copyright || ""}
            </Footer>
          </Layout>
      }
    </Layout >
  )
})

export default App
