import FetchAPI from './rest'
//import OldAPI from './old'
import _ from 'lodash'
import app from 'xadmin'

export default class ProxyAPI {
  constructor(options) {
    if(_.isString(options)) {
      let model = app.get('models')[options]
      if(_.isNil(model)) {
        throw new Error(`model '${options}' not found.`)
      }
      options = model
    }
    return options && new FetchAPI(options)
  }
}