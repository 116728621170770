import React from 'react'
import { api } from 'xadmin'
import { List, Empty, message, Card } from 'antd'

const MessageList = props => {
  const [messageData, setMessageData] = React.useState()

  React.useEffect(() => {
    //获取消息
    api({ name: '' }).fetch('message', {}).then(({ json }) => {
      setMessageData(json)
    }).catch(err => err?.message && message.error(err.message))
  }, [])

  return (
    <Card>
      <List
        dataSource={messageData}
        renderItem={item => (
          <List.Item>
            {item}
          </List.Item>
        )}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
      />
    </Card>
  )
}

export default MessageList
