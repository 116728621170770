import { PageHeader } from 'antd'
import { Layout, Menu } from 'antd'
import React from 'react'
import SplitPane from 'react-split-pane'
import { use } from 'xadmin'

const Page = props => {
  const { title, subTitle, content, sider, breadcrumb, extra, actions, tabs, footer, className, style, children, ...pageProps } = props

  const page = (
    <Layout className={className ? className : 'xadmin-model-list'} style={{ ...style, height: 'calc(100vh - 50px)' }}>
      <PageHeader
        title={title}
        subTitle={subTitle}
        extra={actions}
        tabs={tabs}
        breadcrumb={breadcrumb}
        footer={footer}
        {...pageProps}
        style={{ zIndex: 1, position: 'sticky', 'webkitAppRegion': 'drag', borderBottom: '1px solid #f0f0f0' }}
      >
        {(content || extra) ? (
          <>
            {content && <div className="content padding">{content}</div>}
            {extra && <div className="extraContent">{extra}</div>}
          </>
        ) : null}
      </PageHeader>
      <div style={{ overflowY: 'auto', overflowX: 'hidden', padding: 10 }}>
        {children}
      </div>
    </Layout>
  )
  return sider ? (
    <SplitPane pane2Style={{ width: 0 }} style={{ height: '100vh', position: 'relative' }} split="vertical" defaultSize={240} maxSize={400} minSize={150}>
      {sider}{page}
    </SplitPane>
  ) : page
}

export default Page
