import React from 'react'
import { Card, Button, Row, Form, message } from 'antd'
import { SchemaForm } from 'xadmin-form'
import { Icon, C } from 'xadmin-ui';
import { app, use, api } from 'xadmin'
import logo from './../images/logo.png'

import './index.css'

const DefaultLayout = props => {
  const { children, invalid, handleSubmit, submitting } = props;
  const { _t } = app.context;
  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <Card>
        {children}
        <Row>
          <Button type="primary" loading={submitting} className="loginBtn" onClick={handleSubmit} htmlType="submit" disabled={invalid}>{_t('提交')}</Button>
        </Row>
      </Card>
    </Form>
  );
}

const LogoComponent = (props) => {

  return (
    <>
      <img src={logo} className="guideImg"></img>
      <h3 className="guideTitle">空间管理</h3>
    </>
  )
}

const schema = {
  type: 'object',
  properties: {
    password: {
      type: 'string',
      title: '密码',
      maxLength: 30,
      minLength: 6
    },
    password1: {
      type: 'string',
      title: '再次输入密码',
      maxLength: 30,
      minLength: 6
    }
  },
  form: [
    {
      key: 'password', attrs: { type: 'password' },
      validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
    },
    {
      key: 'password1', attrs: { type: 'password' },
      validate: (value, { password }) => value == password ? ((/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')) : '两次输入密码不一致',
    }
  ],
  required: ['password', 'password1']
}

const Reg = props => {
  const onSubmit = (arg) => {

    return api({ name: '' }).fetch('admin/register', {
      method: 'POST',
      body: JSON.stringify({ password: arg.password })
    }).then(data => {
      message.success("管理员账号注册成功")
      app.go('/login')
    }, err => {
      message.error("管理员账号已存在")
    })
  }

  return (
    <div className="setGuideCard">
      <div className="guideContent" style={{ maxWidth: '688px', background: 'rgba(255,255,255,.1)', padding: '28px' }}>
        <div className="loginGuide" style={{ width: '648px', margin: '0 auto', borderRadius: '5px' }}>
          <div className="loginBox">
            <LogoComponent></LogoComponent>
            <p className="guideUse">初始化密码</p>
            <p className="guideUse2">设置 <b>admin</b> 账户的密码</p>
          </div>
          <SchemaForm
            formKey={'setRegGuide'}
            schema={schema}
            component={DefaultLayout}
            group={C('Form.SimpleGroup')}
            onSubmit={(value) => onSubmit(value)}
          />
          <div className="loginBox">
            <p className="goReg goLogin" onClick={() => app.go('/login')}>已有账号，去登录-></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reg
