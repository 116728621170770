import React, { useState, useEffect } from 'react'
import _, { divide } from 'lodash'
import { Card, Menu, Avatar, message } from 'antd'
import { app, use, config } from 'xadmin'
import { Icon } from 'xadmin-ui';
import reduce from 'async/reduce';
import { UsergroupAddOutlined, ProjectOutlined } from '@ant-design/icons'

const { SubMenu } = Menu;
const MainMenu = (props) => {

  const MenuData = React.useRef()
  const { canClick = true, collapseds, setSelectedKeys, selectedKeys, pathname } = props
  const [{ treeData, loading, }, setState] = useState({ treeData: [], loading: true })

  const MenuClick = url => {
    if (url && canClick) {
      if (url.indexOf('http') >= 0) {
        window.open(url)
      } else {
        app.go(url)
      }
    }
  }

  window.onresize = () => menuHeightResize()

  const menuHeightResize = () => {
    const data = MenuData.current || []

    if (!collapseds) {
      setState(state => ({ ...state, treeData: data }))
      return
    }
    const selectMenu = data.filter(menu => menu.url == selectedKeys[0])
    const Num = parseInt((window.innerHeight - 95 - 90) / 40) - 3
    const MenuNum = Num < 1 ? 1 : Num
    const show_menu = (MenuNum >= 1 && data.slice(0, MenuNum)) || []
    const hide_menu = (MenuNum >= 1 && data.slice(MenuNum, data.length)) || []
    const omit = [{ children: hide_menu, key: 'ellipsis', ellipsis: true }]
    if (selectMenu.length > 0 && _.intersectionBy(selectMenu, show_menu, 'url').length == 0) {
      omit.unshift(selectMenu[0])
      omit[omit.length - 1]['children'] = omit[omit.length - 1]['children'].filter(item => item.url != selectMenu[0]['url'])
    }
    const newdata = (Num >= data.length) ? show_menu : [...show_menu, ...omit]
    setState(state => ({ ...state, treeData: newdata }))
  }

  React.useEffect(() => menuHeightResize(), [collapseds])
  React.useEffect(() => {
    reduce(app.get('menuItems'), [], (prev, item, cb) => {
      if (_.isFunction(item)) {
        item(prev, cb)
      } else if (_.isArray(item)) {
        cb(null, [...prev, ...item])
      } else {
        cb(null, [...prev, item])
      }
    }, (err, treeData, s) => {
      if (err) message.error('读取系统内置菜单项错误')
      else {
        setState({ treeData: travelTree(treeData), loading: false, values: { title: '' } })
        menuHeightResize()
      }
    })
  }, [])

  const travelTree = ts => {
    const data = ts ? ts.map(t => ({
      ...t,
      ...(t.children ? { children: travelTree(t.children) } : {}),
      value: t.value || t.key || t.url
    })) : null
    MenuData.current = data
    return data
  }

  const findTree = (ts, value) => {
    for (let i = 0; i < ts.length; i++) {
      const t = ts[i]
      if (t.value == value) {
        return t
      } else if (t.children) {
        const t1 = findTree(t.children, value)
        if (t1) {
          return t1
        }
      }
    }
    return null
  }

  const GetMenu = (menuTreeData) => {
    if (!menuTreeData || _.isEmpty(menuTreeData)) return
    return menuTreeData.map(item => {
      let titileIcon = <Avatar className="nav-icon" shape="square" />
      if (item.icon) {
        titileIcon = item.icon
      } else {
        titileIcon = <Avatar className="nav-icon" shape="square" src={config('mediaUrl')} />
      }

      if (item.children && item.children.length > 0 && item.url != '/app/model/Dashboard/list') {
        const title = item.ellipsis ? (
          <a className="submenu-title-wrapper">
            <Icon type="ellipsis" style={{ fontSize: 24, color: 'rgba(255, 255, 255, 0.65)', marginLeft: -3 }} />
          </a>
        ) : (
          <a className="submenu-title-wrapper" onClick={() => item.url && MenuClick(item.url)}>
            {titileIcon}
            <span className="nav-text" >{item.title}</span>
          </a>
        )
        return (
          <SubMenu
            className={props.mode == 'horizontal' ? 'submenu-reset' : 'submenu-inline'}
            title={title}
            key={item.url}
          >
            {GetMenu(item.children)}
          </SubMenu>
        )
      }

      return (
        <Menu.Item key={item.url}>
          <a onClick={() => item.url && MenuClick(item.url)}>
            {titileIcon}
            <span className="nav-text" >{item.title}</span>
          </a>
        </Menu.Item>
      )
    })
  }
  const onClick = v => setSelectedKeys(v.keyPath)

  return (
    <Menu
      mode={props.mode}
      theme={props.theme}
      onClick={onClick}
      selectedKeys={selectedKeys}
      style={{ overflowY: 'scroll', overflowY: 'overlay', minHeight: 100 }}
    // className={props.className ? 'main-menu inline-menu-custom' : 'main-menu block-menu-custom'}
    >
      {GetMenu(treeData)}
    </Menu>
  )
}

export default MainMenu
