import { Button, Modal, message, Input } from 'antd'
import _ from 'lodash'
import React from 'react'
import { app, use, api } from 'xadmin'
import { Form } from 'xadmin-form'
import { C } from 'xadmin-ui'

const fields = [
  {
    name: 'password', required: true, label: '新密码', attrs: { type: 'password' },
    validate: value => {
      if (value) {
        if (value.length < 6) return '密码不能少于6位';

        if (value.length > 30) return '密码不能多于30位';
      }
      return (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
    },
  },
  {
    name: 'password1', required: true, label: '重复密码', attrs: { type: 'password' },
    validate: (value, { password }) => {
      if (value) {
        if (value.length < 6) return '密码不能少于6位';
        if (value.length > 30) return '密码不能多于30位';
      }
      return value == password ? ((/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')) : '两次输入密码不一致'
    }
  }
]

const ChangePasswordModal = props => {

  const handleSubmit = async values => {
    try {
      await api(app.get('models').User).save({
        id: props.id, ...values
      }, true)
      message.success('修改密码成功')
      props.onClose()
    } catch (err) {
      return (err.formError || err.json)
    }
  }

  return (
    <Form
      formKey="userChangePassword"
      title="修改用户密码"
      saveText="修改密码"
      show={props.show}
      onClose={props.onClose}
      fields={fields}
      onSubmit={handleSubmit}
      component={C('Form.ModalLayout')}
    />
  )
}

const PasswordHolder = props => {
  const { input: { value, onChange } } = props
  const handleChagne = e => { onChange(e.target.value) }
  return <Input type="password" onChange={handleChagne} value={value} placeholder="请输入当前登录用户的密码" />
}

const CheckPasswordModal = props => {
  const { user } = use('redux', state => { return { user: state.user } })

  const checkFields = [
    {
      name: 'password', required: true, label: '密码', attrs: { type: 'password' },
      description: '当前操作是危险操作，需谨慎操作',
      validate: value => {
        if (value) {
          if (value.length < 6) return '密码不能少于6位';
          if (value.length > 30) return '密码不能多于30位';
        }
        return (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
      },
      component: PasswordHolder
    }
  ]

  const handleSubmit = async values => {
    try {
      await api({ name: `user/changepwd` }).fetch('', {
        method: 'POST', body: JSON.stringify({ password: values.password })
      }).then(res => {
        if (res.status == 200) {
          message.success('校验成功')
          props.getStatus(true)
        }
        props.onClose()
      }).catch(err => {
        message.error('校验失败，密码不正确')
        props.getStatus(false)
      })
    } catch (err) {
      return (err.formError || err.json)
    }
  }

  return (
    <Form
      formKey="userChangePassword"
      title="密码校验"
      saveText="校验"
      show={props.show}
      onClose={props.onClose}
      fields={checkFields}
      onSubmit={handleSubmit}
      component={C('Form.ModalLayout')}
    />
  )
}

const PasswordField = ({ input }) => {
  const { formState: { values } } = use('form')
  const [show, setShow] = React.useState(false)
  const [check, setCheck] = React.useState(false)

  return values && values.id ? [(
    <Button onClick={() => setCheck(true)}>修改密码</Button>
  ), <CheckPasswordModal show={check} onClose={() => setCheck(false)} id={values.id} getStatus={(status) => { setShow(status) }}></CheckPasswordModal>,
  <ChangePasswordModal show={show} onClose={() => setShow(false)} id={values.id} />] : null
}

export default PasswordField
