import React from 'react';
import { use } from 'xadmin'
import { Page } from 'xadmin-ui'
import { SchemaForm } from 'xadmin-form'

import Uploading from './components/Upload';

import { SettingOutlined } from '@ant-design/icons'


const schema = {
  'type': 'object',
  'name': 'settings',
  'resource': 'settings',
  'title': '系统基本信息',
  'key': 'settings',
  'properties': {
    'name': {
      'title': '系统名称',
      'type': 'string'
    },
    'logo': {
      'title': '系统图片',
      'type': 'string',
      'description': '上传空间的logo'
    },
    'backgroundImage': {
      'title': '系统背景',
      'type': 'string'
    },
    'copyright': {
      'title': '系统版权信息',
      'type': 'string'
    },
    'project_logo': {
      'title': '项目图片',
      'type': 'string',
      'description': '上传项目的logo'

    },
    'project_backgroundImage': {
      'title': '项目背景',
      'type': 'string'
    },
  },
  'required': ['name'],
  'form': [
    'name',
    'copyright',
    {
      key: 'logo',
      component: Uploading,
      accept: 'image/*',
      category: 'img'
    },
    {
      key: 'backgroundImage',
      component: Uploading,
      accept: 'image/*',
      category: 'img'
    },
    {
      key: 'project_logo',
      component: Uploading,
      accept: 'image/*',
      category: 'img'
    },
    {
      key: 'project_backgroundImage',
      component: Uploading,
      accept: 'image/*',
      category: 'img'
    },
    '*'
  ]
}

const SettingsPage = () => {
  const { settings, saveSettings } = use('settings')

  const handleSubmit = (values) => {
    console.log(values)
    saveSettings(values)
  }
  return (
    <Page title={<> <SettingOutlined />系统设置</>}
    >
      <SchemaForm
        schema={schema}
        initialValues={settings}
        onSubmit={handleSubmit}
      />
    </Page>
  )
}

export default SettingsPage
