import React from 'react'
import _ from 'lodash'
import { app, use, api } from 'xadmin'

const SettingContext = React.createContext(null)

const SettingRoot = ({ children }) => {
  const { show, ...value } = use('settings.context')
  return show && <SettingContext.Provider value={value}>{children}</SettingContext.Provider>
}

export {
  SettingContext, SettingRoot
}