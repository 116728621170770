import React from 'react'
import { Spin } from 'antd'
import { api, app, config } from 'xadmin'
import { Page, Icon, C } from 'xadmin-ui'
import { Row, Col } from 'antd'
import _ from 'lodash'
import AuthInfoCard from './AuthInfoCard'
import PointCard from './PointCard'
import { CalculatorOutlined } from '@ant-design/icons'
import './../styles/license.css'
import Echarts from './Echarts'
const UpgradelicenseCard = React.lazy(() => import('./UpgradelicenseCard'))

const LicensePage = () => {

  return (
    <Page title={<span><CalculatorOutlined />授权信息</span>}>
      <Row gutter={14}>
        <Col span={12}>
          <AuthInfoCard />
        </Col >
        <Col span={12}>
          <PointCard />
        </Col >
      </Row>
      <React.Suspense fallback={<Spin />}>
        <UpgradelicenseCard />
      </React.Suspense>
    </Page>
  )
}

export default LicensePage

