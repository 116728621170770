
import React, { useEffect } from 'react'
import { api, use } from 'xadmin'
import { Row, Col, Progress, notification, Button } from 'antd'
import moment from 'moment'
import './../styles/license.css'
import { WarningOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'

const AuthInfoCard = () => {

  const data = use('getUserLicense')
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close('notLicense')}>
      关闭提醒
    </Button>
  )

  useEffect(() => {
    let notLicense = localStorage.getItem('notLicense')
    if (!notLicense && data?.remainingTime <= 14) {
      notification.open({
        message: <span><WarningOutlined /> 授权提醒</span>,
        placement: 'bottomRight',
        btn, key: 'notLicense',
        description: '系统授权可用时间不足14天，请及时关注并扩展授权',
        onClick: () => { localStorage.setItem('notLicense', true) },
      })
    }
    // setData(license)
  }, [])

  function getLocalTime(nS) {
    return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
  }

  const titleSty = {
    fontSize: '16px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    fontWeight: 400,
    color: '#303030',
    lineHeight: '22px'
  }
  const dataSty = { fontSize: '18px', fontFamily: 'PingFangSC-Medium, PingFang SC', fontWeight: 500, color: '#303030' }
  //  (当前时间-创建时间)/有效期
  const datePercente = data && ((Date.parse(new Date()) / 1000 - data.createTime) / (data.validityPeriod * 24 * 3600)) * 100
  const datePercent = datePercente && datePercente.toFixed(2)

  return (
    <>
      {
        data?.licenseType == 'platform-spm' ?
        <Row gutter={14}>
          <Col span={24}>
            <div style={{ height: 236, background: '#fff' }}>
              <div style={{ padding: '10px 20px', display: 'flex', lineHeight: '30px' }}>
                <div><span style={{ ...titleSty, marginLeft: '4px' }}>时间信息</span></div>
              </div>
              <div style={{ height: '1px', background: '#E4ECFD' }}></div>
              <Row style={{ padding: '8px 13px 5px', background: '#fff', boxShadow: '0px 2px 4px 0px rgba(231, 231, 231, 0.5)' }}>
                <Col span={8}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ display: 'inline-block' }}>
                      <CheckCircleOutlined style={{ color: "#613BE8" }} /></span>
                    <span style={{ ...titleSty, marginLeft: '15px' }}>创建时间</span>
                  </div>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ ...dataSty, flex: 1, textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {data && moment(data.createTime * 1000).format('YYYY-MM-DD')}
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: '5px 13px', background: '#fff', boxShadow: '0px 2px 4px 0px rgba(231, 231, 231, 0.5)' }}>
                <Col span={8}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ display: 'inline-block' }}>
                      <ClockCircleOutlined style={{ color: "#613BE8" }} />
                    </span>
                    <span style={{ ...titleSty, marginLeft: '15px' }}>截止时间</span>
                  </div>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ ...dataSty, flex: 1, textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {data && moment(data.createTime * 1000).add(data.validityPeriod, 'days').format('YYYY-MM-DD')}
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: '5px 13px', background: '#fff', boxShadow: '0px 2px 4px 0px rgba(231, 231, 231, 0.5)' }}>
                <Col span={8}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ display: 'inline-block' }}>
                      <ClockCircleOutlined style={{ color: "#613BE8" }} />
                    </span>
                    <span style={{ ...titleSty, marginLeft: '15px' }}>剩余时间</span>
                  </div>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ ...dataSty, flex: 1, textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {data && data.remainingTime}天
                  </span>
                </Col>
              </Row>
              <div className="home-progress">
                <Progress width={200} style={{ height: '18px !important' }} strokeColor="#FFC203" percent={datePercent || 0} />
              </div>
            </div>
          </Col>
        </Row > :  null
      }
    </>
  )
}

export default AuthInfoCard
