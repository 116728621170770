/* eslint-disable no-unused-expressions */

import React from 'react';
import { Modal, Upload } from 'antd';
import { api } from 'xadmin';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { getUrl } from '../../util'



const Uploading = (props) => {
  const { input: { value, onChange }, field } = props
  const [state, setState] = React.useState(
    {
      previewVisible: false,
      fileList: value ? [{
        uid: value,
        name: value,
        url: getUrl(value),
      }] : []
    }
  )
  const handleCancel = () => setState(state => ({ ...state, previewVisible: false }))

  const handlePreview = (file) => {
    setState(state => ({
      ...state,
      previewVisible: true,
    }));
  }

  const handleChange = ({ fileList }) => {
    if (fileList.length == 0) {
      onChange(null)
      setState(state => ({ ...state, fileList: [] }))
      return false
    } else {
      const Errors = new Error('上传返回异常信息')
      setState(state => ({ ...state, fileList }))
      const fileurl = fileList[0] && fileList[0].response && fileList[0].response.url
      fileurl ? onChange(fileurl) : Errors
    }
  }
  React.useEffect(() => {
    if (value) {
      setState(state => ({ ...state, fileList: [{ uid: value, name: value, url: getUrl(value) }] }))
    }
  }, [value])

  const { previewVisible, fileList } = state;

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div className="ant-upload-text">上传图片</div>
    </div>
  );



  return (
    <div className="clearfix">
      <div className='uploading' style={{ display: 'flex' }}>
        <Upload
          action={api({ name: 'media' }).getHost() + `resource/${field.category || 'img'}`}
          style={{ display: 'none' }}
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          headers={_.omit(api({ name: 'media' }).getHeaders(), 'Content-Type')}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={getUrl(value)} />
      </Modal>
    </div>
  )
}

export default Uploading