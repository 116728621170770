import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { app, use, api } from 'xadmin'
import { Button, Result, Modal, Radio, Card, Empty, Tag, message, Form, Row, Col, Input, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { DetailGrant, GrantComponent } from './../components/GrantComponent'
import { ActionDelete } from './../components/ActionButton'
import UserStartBtnModal from '../components/UserStartBtnModal'
import { ModelBlock, Model } from 'xadmin-model'
import { C, Page, Icon } from 'xadmin-ui'
import { UserSwitchOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import PasswordField from '../components/PasswordField'
import { GetRequest } from './../common'
import Nodata from './../components/Nodata'
import { BaseFilter } from 'xadmin-model/lib/filter'

const RadioComponent = ({ input }) => {
  const [value, setValue] = React.useState(input.value)
  const onChange = e => {
    setValue(e.target.value);
    input.onChange(e.target.value)
  };
  return (
    <Radio.Group onChange={onChange} value={input.value}>
      <Radio value={0}>个人</Radio>
      <Radio value={1}>企业</Radio>
    </Radio.Group>
  )
}

const DefaultLayout = props => {
  const { model, modelDispatch, modelState } = use('model')
  const { children, invalid, handleSubmit, showCode, submitting, options, field, label, meta, input } = props;
  const { _t } = app.context;
  const attrs = field?.attrs || {}
  const error = meta?.touched && (meta?.error || meta?.submitError)
  const extra = field?.description || field?.help
  const size = (field?.option && field?.option.groupSize) || attrs?.groupSize || {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  const groupProps = { extra, ...size, required: field?.required }

  if (error) {
    groupProps['validateStatus'] = 'error'
    groupProps['hasFeedback'] = true
    groupProps['help'] = error
  }

  const controlComponent = children ? children : (<Input {...input} {...attrs} />)

  return (
    <Form onSubmit={handleSubmit}>
      <Card style={{ marginBottom: '.5rem' }} bodyStyle={{ paddingBottom: 0 }}>
        <Row>
          {children}
        </Row>
        {options && options.submitOnChange ? null : (
          <Row style={{ marginBottom: '.5rem' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button disabled={invalid || submitting} className="searchBtn" type="primary" onClick={handleSubmit}>{_t('Search')}</Button>
              {' '}
              <Button disabled={submitting} onClick={() => {
                app.go('/app/model/User/list')
                modelDispatch({
                  type: 'GET_ITEMS',
                  filter: { ...modelState.filter, skip: 0 },
                  wheres: { filter: {} }
                })
              }}>{_t('重置')}</Button>
            </Col>
          </Row>
        )}
      </Card>
    </Form>
  )
}

const FieldGroup = ({ label, meta, input, field, tailLayout, children }) => {
  const attrs = field.attrs || {}
  const error = meta.touched && (meta.error || meta.submitError)
  const extra = field.description || field.help
  const size = (field.option && field.option.groupSize) || attrs.groupSize || {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  const groupProps = { ...size, required: field.required }

  if (error) {
    groupProps['validateStatus'] = 'error'
    groupProps['hasFeedback'] = true
    groupProps['help'] = error
  }

  const controlComponent = children ? children : (<Input {...input} {...attrs} />)
  return (
    <Col span={8} xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
      <Form.Item
        label={
          label ?
            <>
              <span style={extra ? { marginRight: '6px' } : null} title={label}>{label}</span>
              {
                extra ? (
                  <Tooltip placement="bottom" title={extra}>
                    <QuestionCircleOutlined className="label-des" style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                ) : null
              }
            </> : label
        }
        {...groupProps}>
        {controlComponent}
        {!label && field?.schema?.type == 'boolean' && extra ? <Tooltip placement="bottom" title={extra}>
          <QuestionCircleOutlined className="label-des" style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip> : null}
      </Form.Item>
    </Col>
  )
}

const SearchBar = props => {
  let ops = {
    name: 'submenu',
    component: DefaultLayout,
    group: FieldGroup
  }

  return (
    <BaseFilter {...ops} />
  )
}

const UserPage = props => {
  const { model, modelDispatch, modelState } = use('model')
  const title = model.title
  let icon = model.icon || model.name
  const [filters, setFilters] = React.useState()
  if (_.isString(icon)) icon = <Icon name={icon} />

  let name = GetRequest('name')

  React.useEffect(() => {
    if (name) {
      setFilters({ name })
    } else {
      setFilters({})
    }
  }, [name])

  React.useEffect(() => {
    uploadItems(filters)
  }, [filters])

  const uploadItems = filters => {
    modelDispatch({
      type: 'GET_ITEMS',
      filter: { ...modelState.filter, skip: 0 },
      wheres: { filters: filters }
    })
  }

  const renderActions = () => {
    const { user } = use('redux', state => ({ user: state.user }))
    return user.isAdmin && <Button type='primary' onClick={() => app.go('/app/model/User/add')}><PlusOutlined /> 添加租户</Button>
  }

  return (
    <Model name="User" modelKey={'User'}>
      <Page
        title={(<span>{icon} {title}</span>)}
        actions={renderActions()}
      >
        <ModelBlock name="model.list.submenu" />
        {/* <SearchBar /> */}
        <div key="model-list-subnav" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem' }}>
          <C is="Model.Pagination" /> <C is="Model.ListSubMenu" />
        </div>
        <C is="Model.DataTable" />
        <div key="model-list-downnav" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '.5rem' }}>
          <div /><C is="Model.Pagination" />
        </div>
      </Page>
    </Model>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  User: {
    name: 'User',
    resource: 'user',
    type: 'object',
    icon: <UserSwitchOutlined />, // fa-icon
    title: '租户',
    persistent: true,
    permission: {
      add: true,
      edit: true,
      view: true,
      delete: true,
    },
    properties: {
      name: {
        type: 'string',
        title: '租户名称'
      },
      user: {
        type: 'string',
        title: '租户id',
        showInList: false,
      },
      type: {
        type: 'number',
        title: '租户类型',
        enum: [0, 1],
        enum_title: ['个人', '企业']
      },
      status: {
        type: 'boolean',
        title: '租户状态',
      },
      createTime: {
        type: 'string',
        title: '创建时间',
      },
      industry: {
        type: 'string',
        title: '租户行业',
        enum: ['渔牧业', '智慧城市', '煤炭行业', '制药业', '油田', '供热供暖', '其他']
      },
      grant: {
        type: 'object',
        title: '授权',
        properties: {},
        showInList: false,
      },
      privileges: {
        type: 'array',
        title: '租户项目权限',
        enums: [0, 1, 2, 3],
        fieldType: 'select_multiples',
        items: {
        },
        enum_title: ['不限制', '允许选择我创建的模板', '允许选择市场中的模板', '允许创建空白项目'],
      },
      password: {
        type: 'string',
        title: '租户密码',
        showInList: false,
        minLength: 6
      },
      password1: {
        type: 'string',
        title: '确认密码',
        showInList: false,
        minLength: 6
      },
      remarks: {
        type: 'string',
        title: '描述',
        fieldType: 'textarea'
      }
    },
    orders: { createTime: 'DESC' },
    filters: {
      submenu: [
        'name',
        { key: 'type', component: RadioComponent }, 'industry', { key: 'status', boolLabel: ['启用', '禁用'] }]
    },
    form: [
      { key: 'type', component: RadioComponent }, 'name',
      { key: 'password', component: PasswordField },
      'industry', 'privileges',
      // {
      //   key: 'grant', component: () => '000', validate: (value, s) => {
      //     const formError = window?.portal_form_error
      //     const formErr = formError && Object.keys(formError).every(key => formError[key] === false)
      //     return !formErr && '请检查可用授权'
      //   },
      // },
      { key: 'remarks', type: 'textarea' },
    ],
    // orders: { createTime: 'DESC' },
    batchActions: null,

    required: ['name', 'industry', 'grant', 'type', 'privileges'],
    forceGetItem: true,
    projectFields: ['grant', 'status', 'user'],
    formEffect: form => {

      // form.useField('grant', state => {
      //   if (state.value) form.blur('grant')
      // })

      form.useEffect((s) => { console.log(s) }, ['errors'])

      form.useField('password', state => {
        let value = state.value
        if (value) {
          form.setFieldData('password1', { display: true })
        } else {
          form.setFieldData('password1', { display: false })
        }
      })
    },
    fieldRender: {
      grant: (props) => <DetailGrant name="租户授权" {...props} />,
      createTime: ({ item, wrap: Wrap }) => (
        <Wrap> {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')} </Wrap>
      ),
      privileges: ({ item, wrap, schema }) => {
        const str = item?.privileges?.reduce((prev, cur, i, arr) => {
          prev += schema.enum_title[Number(cur)] + '、'
          return prev
        }, '')
        return str && str.substring(0, str.length - 1)
      },
      status: ({ item, wrap: Wrap }) => {
        return <Wrap>{item.status ? <Tag icon={<CheckCircleOutlined />} color="success">已启用</Tag> : <Tag icon={<MinusCircleOutlined />} color="default">已禁用</Tag>} </Wrap>
      }
    },
    // 项目中所有的数据会被永久删除，该项目将不可用，无法恢复。请确认用户不再需要次项目，再执行此操作
    components: {
      ListPage: UserPage,
      DataEmpty: props => <Card><Nodata /></Card>,
      DataForm: props => {
        const { modelDispatch } = use('model', props)
        const { saveItem } = use('model.save', props)
        const formSchema = (props.id) ? [
          { key: 'type', component: RadioComponent }, 'name',
          { key: 'password', component: PasswordField },
          'industry', 'privileges', {
            key: 'grant', component: GrantComponent, validate: (value, vs) => {

              const formError = window?.portal_form_error
              const formErr = formError && Object.keys(formError).every(key => formError[key] === false)
              console.log(formError, formErr)
              return !formErr && '请检查可用授权'

              // return '请检查可用授权'

            },
          },
          { key: 'remarks', type: 'textarea' },
        ] : [{ key: 'type', component: RadioComponent }, 'name',
        {
          key: 'password', attrs: { type: 'password' },
          validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
        },
        {
          key: 'password1', attrs: { type: 'password' },
          validate: (value, { password }) => value == password ? ((/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')) : '两次输入密码不一致',
        }, 'industry', 'privileges', {
          key: 'grant', component: GrantComponent, validate: (value, s) => {
            const formError = window?.portal_form_error
            console.log(formError)
            const formErr = formError && Object.keys(formError).every(key => formError[key] === false)
            return !formErr && '请检查可用授权'
          },
        },
        { key: 'remarks', type: 'textarea' },
        ]
        const editreq = props.id ? [] : ['password', 'password1']
        const required = ['name', 'industry', ...editreq, 'grant', 'type', 'privileges']

        return (
          <Model name="User" props={{ form: formSchema, required }} >
            <C is="Model.DataForm" {...props}
              onSubmitSuccess
              onSubmit={(vs) => {
                const values = props.id ? _.omit(vs, ['password', 'password1']) : vs
                if (props.id) {
                  api({ name: 'user' })
                    .fetch(`/${props.id}`, { method: 'PATCH', body: JSON.stringify({ ...values }) })
                    .then(({ status }) => {
                      if (status == 200) {
                        message.success('保存租户成功')
                        app.go(`/app/model/User/list`)
                        modelDispatch({ type: 'GET_ITEMS' })
                      }
                    })
                    .catch((err) => { err?.message && message.error(err.message) })
                } else {
                  saveItem({ ...values }, true).then((s) => {
                    app.go(`/app/model/User/list`)
                  })
                    .catch((err) => {
                      err?.name && message.error(err.name)
                    })
                }

              }}
            />
          </Model>)
      }
    },
    listFields: ['name', 'industry', 'type', 'createTime', 'status', 'remarks'],
    itemActions: [
      ({ item }) => (<ProjectModal {...item} />),
      ({ item }) => (<Button onClick={() => app.go(`/app/model/User/${item.id}/detail`)} >详情</Button>),
      ({ item }) => (<UserStartBtnModal {...item} type="user" />),
      'edit',
      ({ item }) => (<ActionDelete {...item} mes="租户及其项目数据均会被删除，确认删除该租户？" />),
    ]
  }
}

const ProjectModal = ({ id, user }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        项目
      </Button>
      <Modal width="80%" destroyOnClose title="项目列表" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Model name="Project" modelKey={"Project" + id} props={{
          filters: {},
          initialValues: { wheres: { filters: { 'user': id } } },
        }}>
          <ModelBlock name="model.list.submenu" />
          <div style={{ marginBottom: '.5rem' }}>
            <C is="Model.Pagination" />
          </div>
          <C is="Model.DataTable" />
        </Model>
      </Modal>
    </>
  )
}
