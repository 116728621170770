import React from 'react'
import { Block } from 'xadmin'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'

import './../styles/layout.css'
import './../styles/admin_layout.css'

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center', paddingBottom: '0.4rem' }}> 
    <div style={{ textAlign: 'center' }}>
      <img draggable="false" style={{ height: 100 }} src={require('./../icon/noData.png')} />
    </div>
    <div style={{ marginTop: '.4rem', textAlign: 'center', lineHeight: 1.5715 }}>暂无数据</div>
  </div>
)

const Main = (props) => {
  return (
    <ConfigProvider locale={zhCN} renderEmpty={customizeRenderEmpty}>
      <Block name="root" />
      {props.children}
    </ConfigProvider>
  )
}
export default Main
