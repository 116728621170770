import _ from 'lodash'
import { app, api } from 'xadmin'
import moment from 'moment'
import { RESTBaseAPI } from 'xadmin'
import fetch from './fetch'
import { message } from 'antd'
export default class API extends RESTBaseAPI {

  constructor(options) {
    super(options)
    this.model = options
    this.host = this.getHost()
    this.headers = this.getHeaders()
    if (this.resource == undefined) {
      console.error(options)
      throw Error('resource is undefind')
    }
  }
  getHost() {

    if (this.model?.name == 'templates') {
      return 'http://airiot.link:1337/'
    } else if (this.model?.name == 'core' || this.model?.name == 'flow') {
      // 本地启动 返回空字符串 打包提交使用 'rest/'
      return 'rest/'
      // return ''
    } else {
      // 本地启动 返回'spm/' 打包提交使用 'rest/spm/'
      // return 'spm/'
       return 'rest/spm/'
    }
    // return 'rest/spm/'
  }


  getHeaders(ifStrapi) {
    const { store } = app.context
    const user = store != undefined ? store.getState().user : null
    const hs = {
      'Content-Type': 'application/json',
    }
    if (user && user.accessToken && !ifStrapi && this.model?.name != 'templates') {
      hs['Authorization'] = 'Bearer ' + user.accessToken
    }
    if (user && user.accessToken && !ifStrapi && (this.model?.name == 'core')) {
      hs['Authorization'] = user.accessToken
    }
    return hs
  }

  fetch(uri, options, isCatch) {
    // 判断是否是strapi接口
    let ifStrapi = uri && uri.indexOf('repos') > -1
    return fetch(this.getHost(ifStrapi) + this.resource + uri, {
      ...options,
      headers: {
        ...(options && options.headers || {}),
        ...this.getHeaders(ifStrapi),
      }
    }, isCatch)
  }

  convert_format(value, schema) {
    if (schema.format == 'datetime') {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    } else if (schema.format == 'date') {
      return moment(value).format('YYYY-MM-DD')
    } else if (schema.type == 'array' && _.isArray(value)) {
      return value.map(i => this.convert_format(i, schema.items))
    } else if (schema.type == 'object' && _.isPlainObject(value)) {
      Object.keys(schema.properties).forEach(k => {
        const p = schema.properties[k]
        value[k] = this.convert_format(value[k], p)
      })
    }
    return value
  }

  convert_item(item, method = 'query') {
    //有的请求数据没有ID，需要用几个属性做联合ID
    if (this.model.idProp) {
      if ((typeof this.model.idProp) == 'object') {
        let unitId = ''
        for (let i of this.model.idProp) {
          unitId += item[i];
        }
        item = { ...item, id: unitId }
      }
      else {
        item = { ...item, id: item[this.model.idProp] }
      }
    } else if (item._id) {
      item.id = item._id
    }
    if (this.model.convertItem) {
      item = this.model.convertItem(item)
    }
    item = this.convert_format(item, this.model)
    return item
  }

  count(filter = {}) {
    let filter_string = encodeURIComponent(JSON.stringify({ where: filter['where'] || {} }))
    return this.fetch(`count?query=${filter_string}`).then(({ json }) => {
      return json['count']
    })
  }

  convert(f) {
    const query = {}

    if (!_.isEmpty(f.order)) {
      let ks = Object.keys(f.order)
        .filter(k => f.order[k] == 'ASC' || f.order[k] == 'DESC')
      if (ks.length > 0)
        query.sort = ks.reduce((prev, key) => ({ ...prev, [key]: f.order[key] == 'ASC' ? 1 : -1 }), {})
    }

    if (!_.isNil(f.skip)) {
      query.skip = parseInt(f.skip)
    }
    if (!_.isNil(f.limit)) {
      query.limit = parseInt(f.limit)
    }

    if (!_.isEmpty(f.fields) || this.model.projectFields) {
      const allProject = [...(this.model.projectFields || []), ...(f.fields || [])]
      const level1 = allProject && allProject.filter(k => k.indexOf('.') == -1)
      const level2 = allProject && allProject.filter(k => k.indexOf('.') > 0)
      const allow = []
      level2.forEach((item, index) => {
        const p = item.slice(0, item.indexOf('.'))
        // 如果是外键不作处理
        if (p == 'model' || p == 'department' || p == 'node') {
          allow.push(level2[index])
        } else {
          if (level1.indexOf(p) == -1) allow.push(item)
        }
      })

      query.project = [...level1, ...allow,].reduce((prev, f) => ({ ...prev, [f]: 1 }), {})
    }

    return query
  }

  convert_value(value) {
    if (_.isPlainObject(value)) {
      // 适配公司查询
      if (value.id) {
        return value.id
      }

      let vs = _.omit(value, 'rule')

      return Object.keys(vs).reduce((prve, key) => {
        prve[key] = this.convert_value(vs[key])
        return prve
      }, {})

    } else if (_.isArray(value)) {
      return value.map(this.convert_where_value.bind(this))
    } else {
      // 去空格处理
      return value && _.isString(value) ? value.trim() : value
    }
  }

  convert_where_value(w) {
    const where = this.convert_where_filters(w)
    return _.isPlainObject(where) ? Object.keys(where).reduce((prev, key) => {
      // 关联数据加上Id
      let fieldKey = key
      const v = this.convert_value(where[key])
      const cv = this.convert_where_filters(v)
      if (_.isPlainObject(cv) && Object.keys(cv).length > 0 && Object.keys(cv)[0].indexOf('$') != 0) {
        prev = Object.keys(cv).reduce((p, k) => {
          if (k == 'gte' || k == 'lte') {
            p[fieldKey] = { ...p[fieldKey], [k]: cv[k] }
          } else {
            p[`${fieldKey}.${k}`] = cv[k]
          }
          return p
        }, prev)
      } else {
        const property = this.model.properties ? this.model.properties[fieldKey] : null
        if (property && (property.type == 'object' || property.type == 'array')) {
          fieldKey = fieldKey
        }
        prev[fieldKey] = cv
      }
      return prev
    }, {}) : where
  }

  convert_where(wheres) {
    let where = _.values(wheres).map(w => this.convert_where_value(w))
    if (where.length > 0) {
      if (where.length > 1) {
        where = where.reduce((p, w) => ({ ...p, ...w }), {})
      } else {
        where = where[0]
      }
    } else {
      where = {}
    }
    return where
  }

  convert_where_filters(v) {
    if (!_.isPlainObject(v)) return v
    const ban = ['like', 'eq', 'gt', 'gte', 'in', 'inq', 'lt', 'lte', 'ne', 'nin', 'and', 'not', 'nor', 'or']
    const cv = Object.keys(v).map(item => {
      const o = v[item]
      if (_.isObject(o)) {
        let k = item
        if (ban.indexOf(item) >= 0 && item.indexOf('$') == -1) {
          k = [`$${item}`]
        }
        return { [k]: this.convert_where_filters(o) }
      } else {
        if (ban.indexOf(item) >= 0 && item.indexOf('$') == -1) {
          return { [`$${item == 'like' ? 'regex' : item}`]: o }
        } else {
          return { [item]: o }
        }
      }
    }).reduce((p, c) => ({ ...p, ...c }), {})
    return cv
  }

  query(filter = {}, wheres = {}, withCount = true, ...params) {
    const where = this.convert_where(wheres)
    const f = { ...params, ...this.model.queryParams, ...this.convert(filter) }
    if (Object.keys(where).length > 0) {
      f['filter'] = where
    }
    if (withCount) { f['withCount'] = true }
    let filter_string = encodeURIComponent(JSON.stringify(f))

    return this.fetch(`?query=${filter_string}`).then(({ json, headers }) => {
      let resultList = json
      if (['api/docker/compose/service', 'api/front'].indexOf(this.model.resource) > -1) { // 服务和模块列表查询
        if (wheres && wheres.searchbar && wheres.searchbar.name && wheres.searchbar.name.like) {
          resultList = json.filter(item => item.name.indexOf(wheres.searchbar.name.like) > -1)
        }
      }
      return { items: resultList.map(this.convert_item.bind(this)), total: withCount ? headers['count'] : resultList.length }
    })
  }

  get(id = '') {
    return this.fetch(`/${id}`).then(({ json }) => {
      if (this.resource == 'core/user') {
        json = _.omit(json, 'password', 'password2')
      }
      return this.convert_item({ ...json, id })
    })
  }

  getOrigin(id = '') {
    return this.fetch(`/${id}`)
  }

  delete(id = '') {
    return this.fetch(`/${id}`, { method: 'DELETE' }).then(({ json }) => ({ ...json, id }))
  }

  convert_data(data) {
    // Object.keys(data).forEach(key => {
    //   if (_.isPlainObject(data[key]) && data[key].id !== undefined) {
    //     data[key + '_id'] = data[key].id
    //   }
    // })
    return _.omit(data, ['__partial__'])
  }

  save(data = {}, partial = false) {
    if (this.resource == 'auth/login') {
      return new Promise((resolve, reject) => {
        fetch(this.getHost() + 'login', {
          method: 'POST',
          body: JSON.stringify(data)
        }).then(({ json }) => {
          const { store } = app.context
          const all = { ...json, ...data }
          store.dispatch({ type: '@@xadmin/AUTH_SIGN_IN', payload: all })
          localStorage.setItem('user', JSON.stringify(all))
          localStorage.setItem(all?.expiresAt, JSON.stringify(all))
          resolve(all)
        }
        ).catch(({ json }) => {
          if (!json?.code) {
            let message_error = Object.keys(json).reduce((prev, key) => {
              if (key == 'code') { prev += '' } else { prev += json[key] }
              return prev
            }, '')
            message.error(message_error)
          }
          reject({ json: { password: json?._error ? json._error : '登录出现错误请联系系统管理员' } })
        })
      })
    }
    if (this.resource == 'auth/password/reset') {
      return new Promise((resolve, reject) => {
        fetch(this.getHost() + 'api/current/password', {
          method: 'PUT'
        }).then(
          ({ json }) => {
            const { store } = app.context
            store.dispatch({ type: '@@xadmin/AUTH_SIGN_OUT', payload: json })
            localStorage.setItem('user', JSON.stringify(json))
            app.go('/login')
            resolve(json)
          }
        ).catch(({ json }) => reject({ json: { password: json ? json._error : '登录出现错误请联系系统管理员' } }))
      })
    }
    if (data.id) {
      return this.fetch(`/${data.id}`, {
        method: !partial ? 'PUT' : 'PATCH',
        body: JSON.stringify(this.convert_data(data))
      }).then(({ json }) => ({
        ...data,
        InsertedID: json.InsertedID
      }))
    } else {
      return this.fetch('', {
        method: 'POST',
        body: JSON.stringify(this.convert_data(data))
      }).then(({ json }) => ({
        ...data,
        id: json.id
      }))
    }

  }

}

export { fetch }
