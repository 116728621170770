import React from 'react'
import { Select } from 'antd'
const { Option } = Select;

const SelectMultiples = props => {
  const { field, field: { schema }, input: { value, onChange } } = props
  const enum_key = schema && schema.enums || []
  const enum_title = schema && schema.enum_title || []
  const ops = enum_key.map((key, i) => ({ id: key, name: enum_title ? enum_title[i] : key }))

  return (
    <Select
      mode="multiple"
      onChange={onChange}
      allowClear
      placeholder={'请选择' + schema.title}
      value={value || undefined}
      {...field.attrs}
    >
      {ops.map((item) => (<Option value={item.id} >{item.name}</Option>))}
    </Select>
  )
}

export default SelectMultiples