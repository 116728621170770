import _fetch from 'isomorphic-fetch'
import { app } from 'xadmin'

const fetch = (url, options = {}, isCatch) => {
  let status, statusText, headers = {}, body, json
  return _fetch(url, options)
    .then(response => {
      for (let pair of response.headers.entries()) {
        headers[pair[0]] = pair[1]
      }
      status = response.status
      statusText = response.statusText
      return response
    })
    .then(response => response.text())
    .then(text => {
      body = text
      try {
        json = JSON.parse(text)
      } catch (e) {
        // not json, no big deal
      }

      const store = app.context.store
      if (status == 401) {
        // 未登录
        store.dispatch({ type: '@@xadmin/AUTH_SIGN_OUT_FINISH' })
        store.dispatch({ type: '@@login/GET_ERROR_MESSAGE' })
        localStorage.setItem('errorMessage', '令牌到期，请重新登录')

        // store.dispatch({
        //   type: '@@xadmin/ADD_NOTICE', payload: {
        //     type: 'danger', headline: '出错了', message: json?.message
        //   }
        // })
        return Promise.reject('未登录')
      } else if (status == 403) {
        // 无权限访问
        store.dispatch({
          type: '@@xadmin/ADD_NOTICE', payload: {
            type: 'danger',
            headline: '访问被拒绝',
            message: { key: '您没有权限进行该操作', content: '您没有权限进行该操作' }
          }
        })
      }

      if (status < 200 || status >= 300) {
        let message = body || statusText
        if (isCatch) return { status, headers, body, json };
        if (json && json.message) {
          message = json.message
          store.dispatch({
            type: '@@xadmin/ADD_NOTICE', payload: {
              type: 'danger', headline: '出错了', message
            }
          })
        }
        if (json && json.disabled) {
          // 用户禁用
          store.dispatch({
            type: '@@xadmin/ADD_NOTICE', payload: {
              type: 'danger', headline: '访问被拒绝', message: json.disabled
            }
          })
        }

        if (status >= 500) {
          const { store } = app.context
          // 服务器错误，弹出错误提示
          store.dispatch({
            type: '@@xadmin/ADD_NOTICE', payload: {
              type: 'danger', headline: '服务器出错了', message: '服务器出现了错误，请联系系统管理员。'
            }
          })
        }

        if (json && json.license) {
          // 授权错误，统一报错
          const { store } = app.context
          store.dispatch({
            type: '@@xadmin/ADD_NOTICE', payload: {
              type: 'danger', headline: '请您更新授权', message: '请您更新授权:' + json.license
            }
          })
          message = json.license
        }

        return Promise.reject({ message, status, headers, json })
      }
      return { status, headers, body, json }
    })
}

export default fetch
