import React from 'react'
import moment from 'moment'
import { api, use, app } from 'xadmin'
import { Loading } from 'xadmin-ui'
import { Card, Menu, Dropdown, Row, Col, Popconfirm, message, Divider, Tooltip, Upload, Button, Checkbox } from 'antd'
import { UploadOutlined, MoreOutlined, DeleteOutlined, EditOutlined, CloseCircleOutlined, EllipsisOutlined, DownloadOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons'
import './../styles/project.css'
import { saveAs } from 'file-saver'
import NoData from './Nodata'

import system from './../images/system.png'
import reception from './../images/reception.png'

const DataCard = props => {
  const { modelDispatch } = use('model')
  const { item } = props
  const [state, setState] = React.useState(true)
  //-----------------
  const plainOptions = [
    // {value: 'role',label: '角色'},
    { value: 'model', label: '模型' },
    { value: 'node', label: '资产' },
    { value: 'report', label: '报表' },
    { value: 'table', label: '工作表' },
    { value: 'dashboard', label: '画面' },
    { value: 'catalog', label: '画面目录'}
  ];
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [temp, setTemp] = React.useState(null);
  // React.useEffect(()=>{
  //   api({ name: '' })
  //   .fetch(`project/_collectionNames?projectId=${item.id}`, {},true)
  //   .then(({ json }) =>{ setTemp(json) })
  // },[])
  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? ['model', 'node', 'report', 'table', 'dashboard', 'catalog'] : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  //----------------
  const confirm = (id) => {
    api({ name: `project/${id}` }).fetch('', { method: 'DELETE' }).then(() => {
      message.success('删除成功')
      modelDispatch({ type: 'GET_ITEMS' })
    })
  }

  const cancel = (e) => {
    console.log(e);
  }

  const disable = item => {
    api({ name: '' }).fetch(`project/${item.id}/status`, {
      method: 'PATCH', body: JSON.stringify({ status: !item.status })
    })
      .then(({ status }) => {
        if (status == 200) {
          message.success(item.status ? '禁用项目' : '启用项目')
          modelDispatch({ type: 'GET_ITEMS' })
        }

      })
      .catch(err => err?.message && message.error(err.message))
  }

  const downloadModel = async (id) => {
    let tableName = ['model', 'node', 'report', 'table', 'dashboard', 'catalog']
    const names = await api({ name: '' })
      .fetch(`project/_collectionNames?projectId=${id}`, {})
      .then(({ json }) => json.filter(i => tableName.includes(i.name)).map(j => j.name))

    const host = api({ name: '' }).getHost()
    const { Authorization } = api({ name: '' }).getHeaders()

    const collections = encodeURIComponent(JSON.stringify(names))

    const href = `/${host}project/_download?projectId=${id}&collections=${collections}&token=${Authorization.split(' ')[1]}`

    const downloadElement = document.createElement('a')
    downloadElement.target = '_blank'
    downloadElement.href = href
    downloadElement.download = item?.name + '.tar.gz'

    document.body.appendChild(downloadElement);

    downloadElement.click()
    downloadElement.remove()
  }


  const prop = {
    name: 'file',
    action: api({ name: '' }).getHost() + ('project/_upload'),
    data: {
      projectId: item.id,
      collections: JSON.stringify(checkedList)   //是否全部覆盖
    },
    showUploadList: false,
    headers: {
      authorization: api({ name: 'media' }).headers.Authorization,
    },
    onChange: ({ file: { status, response } }) => {
      if (status == "uploading") return
      if (response?.status == "OK") {
        message.success('导入成功！')
      } else {
        message.error(response.message)
      }
    }
  };
  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => { app.go(`/app/model/Project/${item.id}/edit`) }}>
        修改项目
      </Menu.Item>
      <Menu.Item icon={<CloseCircleOutlined />}>
        <Popconfirm
          title={item.status ? `禁用后该项目无法登录，数据不会丢失，确认禁用该项目？` : '启用后项目历史数据可以正常使用，用户可继续登录项目，确认启用该项目？'}
          onConfirm={() => disable(item)}
          onCancel={cancel}
          okText={item.status ? '禁用' : '启用'}
          cancelText="取消"
        >
          {item.status ? '禁用项目' : '启用项目'}
        </Popconfirm>
      </Menu.Item>
      {/* onClick={() => { downloadModel(item.id) }} */}
      <Menu.Item icon={<DownloadOutlined />}>
        <Popconfirm title={
          <div style={{ width: '300px' }}>
            {/* <div style={{fontWeight: 700}}>{'['+item?.name+']'}</div> */}
            {temp?.code ? '项目已过期，无法导出项目' : `导出的项目的数据包括项目平台中的模型、资产、画面、报表、工作表以及参数汇总，确认导出【${item?.name}】项目？`}
          </div>}
          onConfirm={() => {
            if (temp?.code) return
            downloadModel(item.id)
          }}
          onCancel={() => { }}
          okText="确认"
          cancelText="取消">
          导出项目
        </Popconfirm>
      </Menu.Item>
      <Menu.Item icon={<UploadOutlined />} >
        <Popconfirm title={
          <div>
            {/* <div style={{fontWeight: 700}}>{'['+item?.name+']'}</div> */}
            {temp?.code ? '项目已过期，无法导入项目' :
              (temp?.length == 1 && temp[0]?.name == 'systemVariable' ?
                '导入的项目包含以下数据，可按需选择导入内容，确认导入该项目？' : '导入项目的数据包含以下内容，可按需选择覆盖的数据，否则将与项目已有数据合并新增，确认导入该项目？')}
            <div style={{ marginTop: '5px' }}>
              <div style={{ lineHeight: '14px', color: '#ff4d4f', marginTop: '5px' }}>
              </div>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                全选
              </Checkbox>
              <Checkbox.Group options={plainOptions} value={checkedList} onChange={onChange} />
            </div>
          </div>}
          // onConfirm={()=>{}}
          // onCancel={()=>{}}
          okText={
            <Upload {...prop} style={{ width: '100%', position: 'relative' }}>
              确定
            </Upload>
          }
          cancelText="取消">
          <Icon type="upload" />导入项目
        </Popconfirm>
        {/* <Upload {...prop} >
          <Icon type="upload" />导入模板
        </Upload> */}
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined />}>
        <Popconfirm
          title={`数据会被永久删除，无法恢复。确认删除该项目？`}
          onConfirm={() => confirm(item.id)}
          onCancel={cancel}
          okText="删除"
          cancelText="取消"
        >
          删除项目
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  const systemIcon = () => (
    <img style={{ margin: '0 10px', width: '20px', height: '20px' }} src={system} />
  )

  const receptionIcon = () => (
    <img style={{ margin: '0 10px', width: '18px', height: '18px' }} src={reception} />
  )

  return (
    <Card hoverable className="project-card"
      title={
        <div className="project-name">
          <Tooltip title={<>
            <span>{item?.name}</span><br />
            <span>{moment(item && item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span></>}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item?.name}</div>
            <div style={{ fontSize: '14px', color: 'rgb(87 91 107)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{moment(item && item.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </Tooltip>
        </div>
      }
      extra={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="点击查看后台">
            <Icon component={systemIcon} {...props} onClick={() => window.open(`/admin/_p_${item.id}`)}></Icon>
          </Tooltip>
          <Tooltip title="点击查看前台">
            <Icon component={receptionIcon} {...props} onClick={() => window.open(`/_p_${item.id}`)}></Icon>
          </Tooltip>
          <Dropdown overlay={menu} >
            <EllipsisOutlined style={{ fontSize: 20, color: '#31395D' }} />
          </Dropdown>
        </div>
      } headStyle={{ background: item.bgColor || '#fff' }} bodyStyle={{ minWidth: 210, minHeight: 160 }}
    >
      <Divider style={{ marginTop: -10, color: '#9A9EAD' }} plain>{item?.user?.name} 项目剩余授权</Divider>

      <Row style={{ marginTop: '30px' }}>
        <Col span="8" style={{ whiteSpace: 'nowrap' }}>
          <div className="card-title-desc">点数</div>
          <div className="card-title-num">{item?.license?.pointCounts}</div>
          <div className="card-line card-line1"></div>
        </Col>
        <Col span="8" style={{ whiteSpace: 'nowrap' }}>
          <div className="card-title-desc">用户数</div>
          <div className="card-title-num">{item?.license?.userCounts <= 0 ? 0 : item?.license?.userCounts}</div>
          <div className="card-line card-line2"></div>
        </Col>
        <Col span="8" style={{ whiteSpace: 'nowrap' }}>
          <div className="card-title-desc">时间（天）</div>
          <div className="card-title-num">{item?.license?.days}</div>
          <div className="card-line card-line3"></div>
        </Col>
      </Row>

    </Card>
  )
}

const ProjectCard = props => {
  const { items, loading } = use('model.list', props)
  return loading ? <Loading /> : items.length > 0 ? (
    <Row gutter={[18, 10]} className="card-row-box">
      {items.map(item => (
        <Col span="6" xs={8} sm={24} md={12} lg={8} xl={6} xxl={4}>
          <DataCard item={item} />
        </Col>)
      )}
    </Row>
  ) : <Card><NoData /></Card>
}

export default ProjectCard
