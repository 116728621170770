
 const GetRequest = (name) => {
  let url = window.location.href //获取url中"?"符后的字串 
  let strs = decodeURI(url.substr(url.indexOf('?')))
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  const r = strs.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

export { GetRequest }
