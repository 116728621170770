import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Col, Row, Form, Input, Select, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { app, api, use } from 'xadmin'
import { Model } from 'xadmin-model'
import { C } from 'xadmin-ui'
import ViewPage from '../schemepreview'
import { SchemaForm } from 'xadmin-form';



import '../styles/view.css'
const { Meta } = Card
const { Option } = Select

const DefaultLayout = props => {
  const { children, invalid, handleSubmit, submitting, getVisible } = props;
  const { _t } = app.context;
  return (
    <Form onSubmit={handleSubmit}>
      <Card style={{ marginTop: 0, borderTop: 'none', color: '#333' }}>
        { children }
        <Row style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" loading={submitting} className="loginBtn" onClick={handleSubmit} htmlType="submit" disabled={invalid}>确定</Button>
          <Button onClick={ ()=> {getVisible(false)} } style={{ marginLeft: '10px' }}>取消</Button>
        </Row>
      </Card>
    </Form>
  );
}

const Template = (props) => {
  const [selected, setSelected] = useState();
  const [ state, setState ] = useState(false)
  const [ data, setData ] = useState()
  const [ id, setId ] = useState()
  const [form] = Form.useForm()
  const [ industry, setIndustry ] = useState(
    [{ key: 'oil', value: '军油' },
    { key: 'other', value: '其他' }]
    )
  const [visible, setVisible] = useState(false);
  const [ visibleProject, setVisibleProject ] = useState(false)
  const [ preViewId, setPreViewId ] = useState()

  const activeStyle = {
    position: 'absolute',
    width: '105%',
    height: '105%',
    borderRadius: 5,
    top: -6,
    left: -8,
    background: 'rgba(24,144,255,0.5)'
  }

  const [ arr, setArr ] = useState([1,2,3,4])
  useEffect(()=>{
    getTemplates()
  },[])

  const getTemplates = value => {
    let wheres;
    if(value?.name && value?.industry) {
      wheres = `?name_contains=${value?.name}&industry=${value?.industry}`
    }else if(value?.name) {
      wheres = `?name_contains=${value?.name}`
    }else if(value?.industry) {
      wheres = `?industry=${value?.industry}`
    }else {
      wheres = ``
    }
    api({name:'templates'}).fetch(`${wheres}`,{}).then(({json})=>{
      setArr(json)
    })
  }

  const onInstall = (id)=>{
    setId(id)
  }

  const Shadow = (props) =>{
    return (state?<div className="hover-model" style={props.style}>   
    <Button className="hover-button" style={{marginTop: !props.style?'100px': '60px'}} onClick={()=> {setVisible(true); setPreViewId(props.schemaId)}}>预览</Button><br/>
    {!props.style && <Button className="hover-button" style={{marginTop: '10px'}} onClick={()=>{ setVisibleProject(true); onInstall(props.schemaId) }}>使用</Button>}
    <Modal visible={visible} width={"calc(100%)"} style={{ top: 0 }} footer={null} onCancel={()=>setVisible(false)} keyboard={false} mask={false} maskClosable={false}>
      <div style={{width: 'calc(100%)',height:'calc(100vh)'}}><ViewPage schemaId={preViewId} setVisible={setVisible} onInstall={onInstall}/></div>
    </Modal>
  </div>:null)
  }

  const onFinish = value => {
    getTemplates(value)
  }

  const handleChange = value => {}
 
  const schema = app.get('models')['Project']

  const onSubmit = (value) => {
    return api({ name: '' }).fetch('project', {
      method: 'POST',
      body: JSON.stringify(value)
    }).then(json => {
      message.success('添加项目成功')
      setVisibleProject(false)
    }, err => {
      message.error(err)
      setVisibleProject(false)
    })
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: '15px' }}>
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          style={{ background: '#fff', padding: '15px', marginBottom: '15px' }}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={4} >
              <Form.Item
                label={'模板名称'}
                name='name'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} >
              <Form.Item
                label={'模板行业'}
                name='industry'
              >
                <Select style={{  }} onChange={handleChange}>
                  {
                    industry.map(item => {
                      return <Option value={item.key}>{item.value}</Option>
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
              }}
            >
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => { form.resetFields(); getTemplates() }}
              >
                重置
              </Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={[18, 10]}>
          {arr.map(item => {
            return (
              <Col span={6}>
                <div onMouseOver={(e)=>{ setState(item)}} onMouseLeave={()=>{setState(false)}}>
                  {state == item ? <Shadow schemaId={item.id}/>: null}
                  <Card style={{ height: 320 }}
                    hoverable title={item.name}
                    onClick={() =>{ setSelected(item);}}
                    cover={<img alt="example" style={{ height: 150 }} src={'http://air.htkjbjf.com:1337'+ item?.card_display?.cover?.url} />}
                  >
                    <div style={item == selected ? activeStyle : {}}></div>
                    <Meta title={item?.developer} description={item?.card_display?.intro} />
                  </Card>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
      {!!data?
      <div onMouseOver={(e)=>{ setState(data)}} onMouseLeave={()=>{setState(false)}} >
        <Shadow schemaId={data.id} style={{ height: 150, width: 500, marginTop: 58}}/>
        <Card style={{ height: 320, width: 500, marginTop: 10 }}
              title={<div>{data.name} <DeleteOutlined style={{float:'right',marginTop: 6}} onClick={()=>{setData(null)}}/> </div>}
              cover={<img alt="example" style={{ height: 150 }} src={'http://air.htkjbjf.com:1337' + data?.card_display?.cover?.url} />}
            >
              <div style={data == selected ? activeStyle : {}}></div>

              <Meta title={data?.developer} description={data?.card_display?.intro} />
            </Card></div>: null}
    <Modal visible={visibleProject} width={"calc(100%)"} style={{ top: 60 }} footer={null} onCancel={()=>setVisibleProject(false)} keyboard={false} mask={false} maskClosable={false}>
      <SchemaForm
        formKey="Project"
        schema={schema}
        initialValues={{
          projectType: '选择方案模板',
          type: id+''
        }}
        component={DefaultLayout}
        onSubmit={onSubmit}
        getVisible={value => { setVisibleProject(value) }}
      />
    </Modal>
    </>
  );
}

export default Template
