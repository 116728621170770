
import { Button, Card, Form, Row, message, Checkbox, Input } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { api, app, use } from 'xadmin';
import './index.css'
import logo from './../images/logo.png'
import moment from 'moment';
import { getUrl } from './../util';

import { SchemaForm } from 'xadmin-form';

const SimpleGroup = ({ label, meta, input, field, children }) => {
  const attrs = field.attrs || {}
  const error = meta.touched && (meta.error || meta.submitError)
  const extra = field.description || field.help
  const groupProps = { extra, required: field.required }

  if (error) {
    groupProps['validateStatus'] = 'error'

    groupProps['help'] = error
  }

  const controlComponent = children ? children : (<Input {...input} {...attrs} />)
  return (
    <Form.Item label={label} {...groupProps}>
      {controlComponent}
    </Form.Item>
  )
}

const LogoComponent = (props) => {
  const { settings } = use('settings')
  const url = settings?.logo ? getUrl(settings?.logo) : logo
  return (
    <>
      <img src={url} className="guideImg"></img>
      <h3 className="guideTitle">{settings?.name || '空间管理'}</h3>
    </>
  )
}

const DefaultLayout = props => {
  const { children, invalid, handleSubmit, submitting } = props;
  const { _t } = app.context;
  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <Card style={{ marginTop: 0, borderTop: 'none', color: '#333' }}>
        {children}
        <Row>
          <Button type="primary" loading={submitting} className="loginBtn" onClick={handleSubmit} htmlType="submit">{_t('Login')}</Button>
        </Row>
      </Card>
    </Form>
  );
}

const Remember = (props) => {
  const { input: { onChange, value } } = props
  return (
    <div className="remember-box" style={{ display: 'flex' }}>
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} className="remember-pwd">记住我</Checkbox>
    </div>
  )
}

const loginForm = {
  type: 'object',
  properties: {
    username: {
      type: 'string',
      title: '用户名'
    },
    password: {
      title: '密码',
      type: 'string',
      maxLength: 30,
      // minLength: 6
    },
    remenber: {
      // title: '记住我',
      // type: 'boolean'
    }
  },
  required: ['username', 'password'],
  form: ['username', {
    key: 'password', attrs: { type: 'password' },
    // validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
  },
    { key: 'remenber', component: Remember }
  ]
}
// eslint-disable-next-line import/no-anonymous-default-export
export default props => {
  const { dispatch } = use('redux')
  const { settings } = use('settings')
  const errorMessage = localStorage.getItem('errorMessage')
  const onSubmit = (value) => {
    const data = { code: value.code, password: value.password, username: value.username }
    return new Promise((resolve, reject) => {
      api({ name: 'login' })
        .fetch('', {
          method: 'POST',
          body: JSON.stringify(data),
        })
        .then(({ json }) => {
          //登录成功
          let data = { ...json, username: value.username }
          if (value && value.remenber) {
            localStorage.setItem('resetCode', false)
          }
          let busLocal = {
            ...data, time: moment().unix()
          }
          localStorage.setItem('bus', JSON.stringify(busLocal))
          localStorage.setItem('user', JSON.stringify(data))
          localStorage.setItem('errorMessage', '')

          dispatch({ type: '@@xadmin/AUTH_SIGN_IN', payload: data })
          dispatch({ type: '@@xadmin/GET_USER_INFO', payload: data, success: true })
          dispatch({ type: '@@xadmin/LOAD_VARIABLE' })

          app.go('/app/dashboard')

          resolve()
        })
        .catch((err) => {
          // message.error(err?.message || '登陆失败')
          localStorage.setItem('resetCode', new Date())
          if (err.status == 400 || err.status == 451) {
            reject({
              ...err.json,
              'FORM_ERROR': err.json && err.json.user
            })
          } else {
            try {
              err.message && Object.values(JSON.parse(err.message)).map(item => { message.error(item) })
              reject(err)
            }
            catch (err) {
              console.log(err)
            }
          }
        })
    })
  }
  const createStyleElement = url => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `body .setGuideCard{ background-image:url(${getUrl(url)}) }`
    document.getElementsByTagName('HEAD').item(0).appendChild(style)
  }

  React.useEffect(() => {
    settings?.backgroundImage && createStyleElement(settings?.backgroundImage)
  }, [settings?.backgroundImage])

  return (
    <div className="setGuideCard" >
      <div className="guideContent" style={{ width: '648px', margin: '0 auto', borderRadius: '5px' }}>
        <div style={{ maxWidth: '688px', background: 'rgba(255,255,255,.1)', padding: '28px' }}>
          <div style={{ maxWidth: '648px', background: 'rgba(255,255,255,.26)', padding: '28px' }}>
            <div className="loginGuide reset_login" >
              <div className="loginBox">
                <LogoComponent></LogoComponent>
              </div>
              {errorMessage ? <span style={{ display: 'flex', justifyContent: 'center', color: '#ff4d4f' }}>{errorMessage}</span> : null}
              <SchemaForm
                formKey={'setLoginGuide'}
                schema={loginForm}
                component={DefaultLayout}
                group={SimpleGroup}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}