import React from 'react'
import { Page, C } from 'xadmin-ui'
import { SchemaForm } from 'xadmin-form';
import { api, app } from 'xadmin';
import { message, Button } from 'antd';
import './../styles/schemepreview.css'
import logo from './../images/logo.png'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

const ViewPage = (props) => {
    const {setVisible, onInstall } = props
    const ref = React.useRef()
    const [ state, setState ] = React.useState(false);
    const [ data, setData ] = React.useState([1,2,3,4])
    let id = props.schemaId
    React.useEffect(props=>{
        api({name:'templates'}).fetch(`/${id}`,{}).then(({json})=>{
            let arr = []
            json.plan_detail = json.plan_detail.replace(/\/uploads/g,'http://airiot.link:1337/uploads')
            // json.plan_detail = json.plan_detail.replace(/\)/g,' =100x100)')
            json.plan_detail.split('###').map(item=>{
                !!item.split('\n')[0] && arr.push({
                    name: item.split('\n')[0],
                    url: item.split('\n')[1]?.split('(')[1]?.split(')')[0]
                }) 
            })
            json.plan_arr = arr
            console.log(arr)
            setData(json)
        })
    },[])
    React.useEffect((props)=>{})
    const onInstallproject = ()=>{
        // sessionStorage.setItem("projectId", data.id);
        // window.history.go(-1)
        onInstall(data.id)
        setVisible(false)
    }
    let array = [
        {name:"模型数",type: 'models'},
        {name:"资产数",type: 'nodes'},
        {name:"画面数",type:'views'},
        {name:"工作表数",type: 'worksheets'}],
    arrmain = [{title: '系统简介',src: ''},{title: '系统简介',src: ''},{title: '系统简介',src: ''}]
    console.log(data)
    return (
        <Page title={'方案展示'}>
            <div >
                <div className="title-box">
                    <div style={{height: '150px',paddingRight: '30px'}}>
                        <img src={logo} />
                    </div>
                    <div>
                        <div className="title">
                            {data.name}
                        </div>
                        <div style={{width:'100%',padding: '15px 0px', fontSize: 16}}>
                            <div style={{lineHeight: '22px',display: 'inline-block', width: '80px',borderRight: '1px solid #333', marginRight: '10px'}}>其他行业</div> 下载次数:  <div className="down-num">{data.num_installed}</div>
                        </div>
                        <div style={{lineHeight: '40px', background: '#fff',padding: '0px 15px',color: '#B3B7C7', marginBottom: 24}}>{data.template_description}</div>
                        <Button onClick={onInstallproject} style={{width: '123px',lineHeight: '17px',borderRadius: '4px',background: '#4F71FF',color: '#fff'}}>使用</Button>
                    </div>
                </div>
                <div className="main">
                    <div className="info-box">
                        <div className="title">
                            应用信息
                        </div>
                        <div className="num-box">
                            {array.map(item=>{
                                return (
                                <div className="nums">
                                    {item.name}
                                    <div className="count">
                                        {data?.app_info && data?.app_info[item.type]}
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="right-side">
                        <div><span style={{fontSize: 20,display: 'inline'}}>类型</span><div className="type-title">项目模板</div></div>
                        
                        <span style={{fontSize: '16px',lineHeight: '16px', color: '#5C6380'}}>开发者联系方式</span>
                        <span style={{fontSize: '16px',lineHeight: '22px',color: '#B3B7C7'}}>航天科技控股集团股份有限公司北京分公司 18201132269@163.com</span>
                        <span style={{fontSize: '16px',lineHeight: '22px',color: '#5C6380'}}>发布时间</span>
                        <span style={{fontSize: '16px',lineHeight: '22px',color: '#B3B7C7'}}>{moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </div>
                </div>
                <div className="main">
                    {/* <div style={{flex: '100%',paddingRight: '100px'}}>
                        <ReactMarkdown>{data.plan_detail}</ReactMarkdown>
                    </div> */}
                    <div >
                        {data?.plan_arr?.map(item=>{
                            return (
                                <>
                                <div style={{lineHeight: '56px',fontSize: '20px', color: '#31395D'}}>{item.name}</div>
                                <img src={item.url} style={{width:'100%'}}/>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default ViewPage