import React from 'react'
import { Page, C } from 'xadmin-ui'
import { SchemaForm } from 'xadmin-form';
import { api, app } from 'xadmin';
import { message } from 'antd';

const UserChangePassword = {
  type: 'object',
  properties: {
    oldPassword: {
      title: '原密码',
      type: 'string'
    },
    newPassword: {
      title: '新密码',
      type: 'string'
    },
    newPassword2: {
      title: '重复密码',
      type: 'string'
    }
  },
  form: [
    {
      key: 'oldPassword', attrs: { type: 'password' },
      validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
    },
    {
      key: 'newPassword', attrs: { type: 'password' },
      validate: value => (/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')
    },
    {
      key: 'newPassword2', attrs: { type: 'password' },
      validate: (value, { newPassword }) => value == newPassword ? ((/^(?![^a-zA-Z]+$)(?!\D+$)/ig.test(value) ? null : '密码必须为字母与数字组合')) : '两次输入密码不一致'
    }
  ]
}

const ChangePassword = props => {

  const onSubmit = value => {
    api({ name: '' })
      .fetch('login/current/password', {
        method: 'PUT',
        body: JSON.stringify({ oldPassword: value.oldPassword, newPassword: value.newPassword })
      })
      .then(({ json }) => {
        message.success('修改成功请重新登录')
        app.go('/login')
      })
      .catch(err => {
        err?.message && message.error(err.message)
      })
  }

  return (
    <Page title={'修改密码'}>
      <SchemaForm
        formKey='changeCurrentPassword'
        schema={UserChangePassword}
        onSubmit={(value) => onSubmit(value)}
      />
    </Page>
  )
}


export default ChangePassword