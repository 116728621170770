
import moment from 'moment'
import { api, use } from 'xadmin';
import React, { useState } from 'react';
import { Modal, Button, Table, Radio, Divider, message, Popconfirm, Tooltip, Alert } from 'antd';
import NoData from './Nodata'

const columns = [
  {
    title: '项目名称',
    dataIndex: 'name',
  },
  {
    title: '项目描述',
    dataIndex: 'remarks',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
  },
]

const StartBtnModal = ({ user, id, name, status, type }) => {
  const [data, setData] = useState([])
  const { modelDispatch } = use('model')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const showModal = () => {
    setIsModalVisible(true);
    api({ name: 'project' }).query({ fields: [] }, { where: { 'user': id } })
      .then(({ items }) => {
        setData(items)
        setSelectedRowKeys(items.map(item => item.id))
      })
  };

  const handleOk = () => {
    setIsModalVisible(false);
    api({ name: 'user' }).fetch(`/${id}/enable`, {
      method: 'PATCH',
      body: JSON.stringify(selectedRowKeys)
    })
      .then(({ status }) => {
        if (status === 200) {
          message.success(name + ' 启用成功')
          modelDispatch({ type: 'GET_ITEMS' })
          setSelectedRowKeys([])
        }
      })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRowKeys([])
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    selectedRowKeys
  }

  return (
    <>
      { status ? <ActionBan id={id} /> : <Button type="primary" onClick={showModal}> 启用 </Button>}
      <Modal 
        // title="启用项目"
        title={ type && type == 'user' ? `启用${name}租户` : '启用项目' }
        width="60%"
        visible={isModalVisible}
        onOk={handleOk}
        destroyOnClose
        // okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
        onCancel={handleCancel} >
        { type && type == 'user' ? <Alert style={{ marginBottom: '1rem' }} message="注意：启用前请确定同步启用的项目。启用该租户后，租户及被启用的项目可正常登录，确认启用该租户？" type="warning" /> : null }
        <Table
          pagination={false}
          columns={columns}
          dataSource={data.map(item => ({ ...item, key: item.id }))}
          rowSelection={{ type: 'checkbox', ...rowSelection }}
          locale={{ emptyText: <NoData /> }}
        />
        <p style={{ paddingTop: 10 }}>
          {`共有${data.length}数据${selectedRowKeys.length > 0 ? (',已选择' + selectedRowKeys.length + '条') : ''}`}
        </p>
      </Modal>
    </>
  );
};

const ActionBan = ({ id }) => {
  const { modelDispatch } = use('model')
  const mes = '禁用后该租户及租户创建的项目均不可使用，数据不会丢失，确认禁用该租户？'

  const onClick = () => {
    if (!id) return
    api({ name: 'user/' }).fetch(id + '/disable', {
      method: 'PATCH', body: ''
    })
      .then(({ status: resStatus }) => {
        if (resStatus === 200) {
          message.success('禁用成功')
          modelDispatch({ type: 'GET_ITEMS' })
        }
      })
  }

  return <Popconfirm key="action-delete" title={mes} overlayStyle={{ width: 260 }}
    onConfirm={onClick} okText={'确定'} cancelText='取消'>
    <Tooltip placement="top" title='禁用'>
      <Button >禁用</Button>
    </Tooltip>
  </Popconfirm>
}

export default StartBtnModal