import React from 'react'
import { Form, Input, Col, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const FieldGroup = ({ label, meta, input, field, tailLayout, children }) => {
  const attrs = field.attrs || {}
  const error = meta.touched && (meta.error || meta.submitError)
  const extra = field.description || field.help
  const size = (field.option && field.option.groupSize) || attrs.groupSize || { 
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19,  offset: tailLayout ? 5 : 0 }
    }
  }

  const groupProps = { ...size, required: field.required }

  if (error) {
    groupProps['validateStatus'] = 'error'
    groupProps['hasFeedback'] = true
    groupProps['help'] = error
  }

  const controlComponent = children ? children : (<Input {...input} {...attrs} />)
  return (
    <Form.Item 
      label={
        label ? 
        <>
          <span style={ extra ? { marginRight: '6px' } : null} title={label}>{label}</span>
          {
            extra ? (
              <Tooltip placement="bottom" title={extra}>
                <QuestionCircleOutlined className="label-des" style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : null
          }
        </> : label
      } 
      {...groupProps}>
      {controlComponent}
      { !label && field?.schema?.type == 'boolean' && extra ? <Tooltip placement="bottom" title={extra}>
        <QuestionCircleOutlined className="label-des" style={{ color: 'rgba(0,0,0,.45)' }} />
      </Tooltip> : null }
    </Form.Item>
  )
}

export default {
  components: {
    'Form.FieldGroup': FieldGroup,
  }
}
