/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './styles/index.css';
import { app, api, use } from 'xadmin';
import _ from 'lodash'
import hooks from './hooks';

import i18n from 'xadmin-i18n'
import auth from 'xadmin-auth'
import form from 'xadmin-form'
import ui from 'xadmin-ui'
import model from 'xadmin-model'
import relate from 'xadmin-model/lib/relate'
import filter from 'xadmin-model/lib/filter'
import actions from 'xadmin-model/lib/actions'

import loading from 'xadmin-ui/lib/loading'
import splashscreen from 'xadmin-ui/lib/splashscreen'

import modalform from 'xadmin-model/lib/modalform'
import search from 'xadmin-model/lib/search'
import reldetail from 'xadmin-model/lib/reldetail'

import components from 'xadmin-antd'

import models from './models'
import API from './api'
import layout from './layout'
import icons from './icon'
import SelectMultiples from './components/SelectMultiples'
import ColorPicker from './components/ColorPicker'
import Number from './components/Number'
import License from './components/License'

import 'moment/locale/zh-cn'
import 'antd/dist/antd.min.css'
import logo from './icon/logo.png';
import { ProjectOutlined, UserSwitchOutlined, CalculatorOutlined, KeyOutlined, SettingOutlined } from '@ant-design/icons'
import Reg from './userinfo/Reg'
import ChangePassword from './userinfo/ChangePassword'
import UserProfile from './userinfo/ProfileSetting'
import Login from './userinfo/Login'
import ViewPage from './schemepreview'
import Template from './components/Template'
import SettingsPage from './settings'
import { message } from 'antd';
import { SettingRoot } from './context'
import { getUrl } from './util'

const auths = {
  ...auth,
  models: {
    Login: {
      type: 'object',
      resource: 'auth/login',
      name: 'Login',
      properties: {
        username: {
          type: 'string',
          title: '用户名'
        },
        password: {
          title: '密码',
          type: 'string',
          maxLength: 30,
        }
      },
      required: ['username', 'password'],
      form: ['username', { key: 'password', attrs: { type: 'password' } }]
    }
  },
  routers: {
    '/': [
      {
        path: 'regest',
        component: Reg
      },
      {
        path: 'login',
        component: Login
      }
    ],
    '/app/': [{
      path: 'change_current_password',
      component: ChangePassword
    },
    {
      path: 'user/center',
      component: UserProfile
    },
    {
      path: 'schemepreview',
      component: ViewPage
    },
    {
      path: 'license',
      component: License
    },
    {
      path: 'templates',
      component: Template
    },
    {
      path: 'settings',
      component: SettingsPage
    }
    ]
  }
}

const SiteTitle = () => {
  const { settings } = use('settings')
  return (
    <div className='Settings-title'
      style={{ cursor: 'pointer' }}
      onClick={() => { app.go('/app/dashboard') }}
    >
      {settings?.name || '空间管理'}
    </div>
  )
}
const SiteLogo = () => {
  const { settings } = use('settings')
  const url = settings?.logo && getUrl(settings?.logo)
  return settings?.logo ? <img style={{ width: '40%' }} src={url} /> : null
}

app
  .use(i18n)
  .use(ui)
  .use(loading)
  .use(icons)
  .use(form)
  .use(model)
  .use(filter)
  .use(auths)
  .use(actions)
  .use(relate)
  .use(modalform)
  .use(search)
  .use(reldetail)
  .use(components)
  .use(splashscreen)
  .use({
    config: {
      api: API,
      locale: {
        lng: 'zh_Hans', moment: 'zh-cn'
      },
      router: 'hash',
      auth: {
        can_signin: true,
        // can_signup: true, 
        can_reset_password: false,
        persist_type: 'session-storage'
      },
      date_format: {
        time: 'HH:mm:ss', date: 'YYYY-MM-DD', datetime: 'YYYY-MM-DD HH:mm:ss'
      },
      filter: {
        textDefaultSearch: true,
        submenuShowAllFilter: true
      },
      'site.title': <SiteTitle />,
      'site.logo': <SiteLogo />
    },
    components: layout.components,
    items: {
      menuItems: { type: 'array' }
    },
    root_component: (app) => (children) => (
      <SettingRoot>{children}</SettingRoot>
    ),
    menuItems: [
      { title: '项目管理', url: '/app/model/Project/list', icon: <ProjectOutlined /> },
      { title: '租户管理', url: '/app/model/User/list', icon: <UserSwitchOutlined /> },
      { title: '授权管理', url: '/app/license', icon: <CalculatorOutlined /> },
      { title: '系统设置', url: '/app/settings', icon: <SettingOutlined /> },
    ],
    schema_converter: [(f, schema, options) => {
      if (schema.type === 'array' && schema.fieldType == 'select_multiples') {
        f.type = 'select_multiples'
      }
      if (schema.type === 'string' && schema.fieldType == 'color_picker') {
        f.type = 'color_picker'
      }
      return f
    }],
    form_fields: {
      select_multiples: {
        component: SelectMultiples
      },
      color_picker: {
        component: ColorPicker
      },
      number: {
        component: Number,
        parse: (value) => {
          return value === '' || value == null ? null : parseFloat(value)
        },
        attrs: {
          type: 'number',
          style: {
            width: 200
          }
        }
      }
    },
    context: app => (context, cb) => {
      const { store } = context
      let user = localStorage.getItem('user')
      let userJson = localStorage.getItem(user?.expiresAt)

      const link = document.createElement('link')
      link.setAttribute('rel', 'icon');
      link.setAttribute('type', 'image/x-icon');
      link.setAttribute('href', './images/logo.png')
      let heads = document.getElementsByTagName('head')
      if (heads.length) heads[0].appendChild(link)

      if (userJson) {
        const user = JSON.parse(userJson)
        store.dispatch({ type: '@@xadmin/AUTH_SIGN_IN', payload: user })
      } else {
        api({ name: '' }).fetch('admin/check')
          .then(data => {
            if (data.status !== 200) {
              app.go('/regest')
              message.info('请先设置admin密码，才可以登录！')
            } else if (data.status && data.status == 'OK') {
              // 已经创建管理员，可直接登陆
            }
          }, err => {
            console.log("err", err)
            app.go('/regest')
          })
      }

      cb(null, context)

    },
    models,
    hooks
  }).start({ container: '#root' })

