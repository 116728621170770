const icons = [
  {
    name: 'Service',
    url: require('./Service.svg')
  },
  {
    name: 'User',
    url: require('./User.svg')
  },
  {
    name: 'Modular',
    url: require('./Modular.svg')
  }
]

export default {
  name: 'iot.icon',
  items: {
    icons: { type: 'array' }
  },
  icons
}