import _ from 'lodash'
import React from 'react'
import { app, use, api } from 'xadmin';
import { Button, Radio, Input, Form, Card, Tooltip } from 'antd';
import { Model } from 'xadmin-model'
import ProjectCard from './ProjectCard';
import { ModelBlock } from 'xadmin-model'
import { Page, Icon, C } from 'xadmin-ui'
import { PlusOutlined } from '@ant-design/icons';
import { GetRequest } from './../common'
import { BaseFilter } from 'xadmin-model/lib/filter'
import './../styles/project.css'

const UserRadio = ({ userId, getChangeItem }) => {
  const { user } = use('redux', state => ({ user: state.user }))
  const [checked, SetChecked] = React.useState(userId?.user || {})
  const styfill = { background: '#F6A308', borderColor: '#F6A308', color: '#fff' }
  const styborder = { borderColor: '#F6A308', color: '#F6A308', boxShadow: 'none' }

  React.useEffect(() => {
    let defalutValue = userId?.user || {}
    // changeIsAdmin(defalutValue)
    SetChecked(defalutValue)
  }, [userId])

  const changeIsAdmin = value => {
    setTimeout(() => {
      getChangeItem(value)
      SetChecked(value)
    })
  }

  if (!user.isAdmin) return null

  return (
    <Radio.Group value={checked} onChange={e => changeIsAdmin(e.target.value)} buttonStyle="solid" style={{ marginRight: '.5rem' }}>
      <Radio.Button value={{}}
        style={_.isEmpty(checked) ? styfill : styborder}
      >全部项目</Radio.Button>
      <Radio.Button value={user.userId}
        style={checked == user.userId ? styfill : styborder}
      >我的项目</Radio.Button>
      <Radio.Button value={{ '$ne': user.userId }}
        style={checked['$ne'] == user.userId ? styfill : styborder}
      >租户项目</Radio.Button>
    </Radio.Group>
  )
}


const DefaultLayout = props => {
  const { model, modelDispatch, modelState } = use('model')
  const { children, invalid, handleSubmit, showCode, submitting } = props;
  const { _t } = app.context;
  return (
    <Form onSubmit={handleSubmit} layout="vertical" className="admin_vertical">
      {children}
      <Button type="primary" loading={submitting} className="searchBtn" onClick={handleSubmit} htmlType="submit">{_t('搜索')}</Button>
      <Button loading={submitting} onClick={() => {
        app.go('/app/model/Project/list')
        modelDispatch({
          type: 'GET_ITEMS',
          filter: { ...modelState.filter, skip: 0 },
          wheres: { filter: {} }
        })
      }} htmlType="submit">重置</Button>
    </Form>
  )
}

const SearchBar = props => {
  let ops = {
    name: 'navform',
    component: DefaultLayout,
    group: 'Form.InlineGroup'
  }

  return (
    <BaseFilter {...ops} />
  )
}

const ProjectPage = (props) => {
  const { model, modelDispatch, modelState } = use('model')
  const { user } = use('redux', state => ({ user: state.user }))
  const [RadioV, setRadioV] = React.useState(localStorage.getItem(user?.username + 'projectStyle'))
  const styfill = { background: '#F6A308', borderColor: '#F6A308', color: '#fff' }
  const styborder = { borderColor: '#F6A308', color: '#F6A308', boxShadow: 'none' }
  const [filters, setFilters] = React.useState()
  const title = model.title
  const data = use('getUserLicense')

  let name = GetRequest('name')
  let userId = GetRequest('userId')
  let status = GetRequest('status')
  let type = GetRequest('type')

  React.useEffect(() => {
    if (name) {
      setFilters({ name })
    } else if (userId) {
      userId === user?.userId ? setFilters({ user: userId }) : setFilters({ user: JSON.parse(userId) })
    } else if (status && _.isBoolean(JSON.parse(status))) {
      setFilters({ status: JSON.parse(status) })
    } else if (type) {
      type && setFilters({ projectType: type })
    } else {
      setFilters({})
    }
  }, [name, userId, status, type])

  React.useEffect(() => {
    uploadItems(filters)
  }, [filters])

  const changeModelItem = value => {
    uploadItems({ user: value })
  }

  const uploadItems = filters => {
    modelDispatch({
      type: 'GET_ITEMS',
      filter: { ...modelState.filter, skip: 0 },
      wheres: { filters: filters }
    })
  }

  let icon = model.icon || model.name
  if (_.isString(icon)) icon = <Icon name={icon} />

  const renderActions = () => {
    return (<div>
      <ModelBlock name="model.list.navbtn" />
      <UserRadio userId={filters} getChangeItem={(value) => { changeModelItem(value) }}></UserRadio>
      <Radio.Group value={RadioV} onChange={onChangeRadio} buttonStyle="solid">
        <Radio.Button value="card" style={RadioV == 'card' ? styfill : styborder}>卡片形式</Radio.Button>
        <Radio.Button value="list" style={RadioV == 'list' ? styfill : styborder}>列表形式</Radio.Button>
      </Radio.Group>

      {
        data?.licenseType == 'platform-spm' ? 
          <Button type='primary' style={{ marginLeft: 15 }} onClick={() => app.go('/app/model/Project/add')}><PlusOutlined /> 添加项目</Button>
        :
        <Tooltip title="上传授权后才可以添加项目">
          <Button disabled type='primary' style={{ marginLeft: 15 }} onClick={() => app.go('/app/model/Project/add')}><PlusOutlined /> 添加项目</Button>
        </Tooltip>
      }
    </div>)
  }
  const onChangeRadio = (e) => {
    localStorage.setItem(user?.username + 'projectStyle', e.target.value)
    setRadioV(e.target.value)
  }

  return (
    <Model name="Project" modelKey={'Project'}>
      <Page title={(<span>{icon} {title}</span>)}
        subTitle={<ModelBlock name="model.list.nav" />}
        // subTitle={<SearchBar />}
        actions={renderActions()}
      >
        <div key="model-list-subnav" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem' }}>
          <C is="Model.Pagination" /> <C is="Model.ListSubMenu" />
        </div>
        {RadioV == 'list' ? <C is="Model.DataTable" /> : <ProjectCard />}
        <div key="model-list-downnav" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '.5rem' }}>
          <div></div> <C is="Model.Pagination" />
        </div>
      </Page>
    </Model>
  )
}


export default ProjectPage


