import React from 'react'
import Main from './Main'
import App from './App'
import Page from './Page'
import _ from 'lodash'
import { use } from 'xadmin'
import { _t } from 'xadmin-i18n'
import { Loading, C } from 'xadmin-ui'
import { ModelBlock } from 'xadmin-model'
import group from './group'

const Dashboard = React.lazy(() => import('./Dashboard'))

const ModelDetailPage = ({ params }) => {
  const { model } = use('model')

  const DetailComponent = (model.components && model.components.DataDetail) || C('Model.DataDetail')

  return (
    <Page className={`xadmin-model-detail-${model.key}`}
      title={model.title} onBack={() => window.history.back()}
      subTitle={<ModelBlock name="model.detail.nav" />}
      actions={<ModelBlock name="model.detail.navbtn" />}>
      <ModelBlock name="model.detail.before" />
      <DetailComponent id={params && params.id} />
      <ModelBlock name="model.detail.after" />
    </Page>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  components: {
    'Model.DetailPage': ModelDetailPage,
    ...group.components,
    Main, App, Page,
    Dashboard: () => (
      <React.Suspense fallback={<Loading />}>
        <Dashboard />
      </React.Suspense>
    )
  }
}
