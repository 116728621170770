import React from 'react'
import _ from 'lodash'
import { use, api } from 'xadmin'
import moment from 'moment'
import { SchemaForm } from 'xadmin-form';
import AuthInfoCard from './AuthInfoCard'

import { Card, Form, Row, Col, Divider, DatePicker } from 'antd'

function getLocalTime(nS) {
  return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
}

const FormLayout = (props) => {

  const { model } = use('model')
  const currentData = window.currentData
  const href = window.location.href
  const license = use('getUserLicense')

  const { children, handleSubmit, } = props
  const { user } = use('redux', state => { return { user: state.user } })
  const { days, effectiveUser, effectivePoints, endDate } = (!user.isAdmin && currentData && currentData.user != 'admin') ? use('getAuthorization') : use('getAuthorization', currentData && currentData.user)

  // 当前是Project 并且 这个项目的租户不是admin 并且 admin在添加的情况下不显示剩余授权
  const display = model?.name == 'Project' && currentData && currentData.user != 'admin' && href.indexOf('/Project/add') == -1

  return <Card style={{ border: '1px solid #d9d9d9' }}>
    <Row>
      {/* 管理员下查看租户项目显示剩余授权 */}
      {(display || !user.isAdmin) ?
        <Col flex={6}>
          <Divider style={{ margin: 0 }}>租户可用授权</Divider>
          <div style={{ margin: '0 auto', textAlign: 'center', fontSize: '15px' }}>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2} >结束时间</Col>
              <Col flex={3}>{moment(endDate).format('YYYY-MM-DD')}</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2} >时长</Col>
              <Col flex={3}>{days > 0 ? days + ' 天' : '已过期' + Math.abs(days) + "天"}</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>点数</Col>
              <Col flex={3}>{effectivePoints} 点</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>用户</Col>
              <Col flex={3}>{effectiveUser} 名</Col>
            </Row>
          </div>
        </Col> : < Col flex={6}>
          <Divider style={{ margin: 0 }}>客户可用授权</Divider>
          <div style={{ margin: '0 auto', textAlign: 'center', fontSize: '15px' }}>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2} >结束时间</Col>
              <Col flex={3}>{license && moment(license.createTime * 1000).add(license.validityPeriod, 'days').format('YYYY-MM-DD')} </Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2} >时长</Col>
              <Col flex={3}>{license && license.remainingTime} 天</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>点数</Col>
              <Col flex={3}>不限制</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>用户</Col>
              <Col flex={3}>不限制</Col>
            </Row>
          </div>
        </Col>}
      <Col flex={6}>
        <Divider style={{ margin: 0 }}>{model?.name == 'User' ? '租户授权' : '项目授权'}</Divider>
        <Form style={{ margin: '0 auto' }}
          wrapperCol={{ span: 10, offset: 3 }}
          onSubmit={handleSubmit}> {children} </Form>
      </Col>
    </Row>
  </Card>
}

const StartDatePicker = ({ input }) => {
  const { user } = use('redux', state => ({ user: state.user }))

  const disabledDate = (current) => current && (current < moment().endOf('day').subtract(1, 'd'))

  const onChange = (value) => input.onChange(value && value.format('YYYY-MM-DD'))

  return <DatePicker
    {...input}
    format="YYYY-MM-DD"
    onChange={onChange}
    disabledDate={disabledDate}
    value={input.value && moment(input.value)}
  />
}

const schemaForm = {
  type: 'object',
  properties: {
    startTime: {
      type: 'string',
      title: '起始时间',
      format: 'date',
      field: {
        component: StartDatePicker
      },
      description: '项目的有效起始时间，项目有效时才可以登录平台'
    },
    duration: {
      type: 'number',
      title: '时长',
      field: { attrs: { min: "1", max: '9999999', style: { width: 200 } } },
      description: '项目的有效时长'
    },
    point: {
      type: 'number',
      title: '点数',
      field: { attrs: { min: "1", max: '9999999', style: { width: 200 } } }
    },
    userCount: {
      type: 'number',
      title: '用户数',
      field: { attrs: { min: "1", max: '9999999', style: { width: 200 } } }
    }
  },
  form: ['*'],
  required: ['startTime', 'duration', 'point', 'userCount'],
}
const GrantComponent = (props) => {
  const { input, type } = props
  const { form } = use('form')
  const { initialValues } = form.getState()
  const { user } = use('redux', state => ({ user: state.user }))
  const license = use('getUserLicense')
  window.currentData = { ...user, ...initialValues }
  const currentData = window.currentData
  const authorization = (!user.isAdmin && currentData && currentData.user != 'admin') ? use('getAuthorization') : use('getAuthorization', currentData && currentData.user)
  const initGrant = initialValues?.grant || {}

  schemaForm.properties.startTime.description = type == "project" ? '项目的有效起始时间，项目有效时才可以登录平台' : '租户的有效起始时间，租户有效时才可以登录租户平台'
  schemaForm.form = [
    '*',
    {
      key: 'startTime', validate: (value, vs) => {
        if (!value) {
          window['portal_form_error'] = {
            ...window['portal_form_error'],
            startTime: true
          }
          return '请选择起始时间'
        } else {
          window['portal_form_error'] = {
            ...window['portal_form_error'],
            startTime: false
          }
        }
      }
    },
    {
      key: 'duration', validate: (value, vs) => {
        const startTime = vs?.startTime
        const adminAuth = value && license && moment(license.createTime * 1000).add(license.validityPeriod, 'days').isBefore(moment(startTime).add(value, 'days'))
        if (value <= 0 || String(value).indexOf(".") > -1 || value === undefined || String(value).indexOf("+") > -1) {
          window['portal_form_error'] = {
            ...window['portal_form_error'],
            duration: true
          }
          return '输入错误不能0、小数或负数等'
        }
        // console.log('==', license, moment(license.createTime * 1000).add(license.validityPeriod, 'days').format('YYYY-MM-DD'), moment(startTime).add(value, 'days'))
        //  项目授权在租户授权束时间在结之后
        const noAdmin = authorization?.deadline && !moment(startTime).add(value - 1, 'days').isBefore(authorization?.deadline)
        // user.isAdmin ? adminAuth : noAdmin
        if (noAdmin || adminAuth) {
          window['portal_form_error'] = {
            ...window['portal_form_error'],
            duration: true
          }
          return '不能大于租户可用授权结束时间'
        } else {
          window['portal_form_error'] = {
            ...window['portal_form_error'],
            duration: false
          }
        }
      }
    },
    {
      key: 'point', validate: (value) => {
        return validateLicense('point', 'effectivePoints', value, authorization, initGrant, initialValues, user)
      }
    },
    {
      key: 'userCount',
    },
  ]

  return <SchemaForm
    formKey='GrantComponent'
    schema={schemaForm}
    onChange={input.onChange}
    component={FormLayout}
    initialValues={input.value}
  />
}

const validateLicense = (name, Aname, value, authorization, initGrant, initialValues, user) => {
  if (value <= 0 || String(value).indexOf(".") > -1 || value === undefined || String(value).indexOf("+") > -1) {
    window['portal_form_error'] = {
      ...window['portal_form_error'],
      [name]: true
    }
    return '输入错误不能0、小数或负数等'
  }

  if (initialValues?.id) {
    const d = (Number(initGrant[name]) - Number(value))
    // d大于0就是减少 小于0增加
    if (d > 0 ? false : -d > authorization[Aname]) {
      window['portal_form_error'] = {
        ...window['portal_form_error'],
        [name]: true
      }
      return '不能大于可用授权或0和负数'
    } else {
      window['portal_form_error'] = {
        ...window['portal_form_error'],
        [name]: false
      }
    }
  } else {
    if (value > authorization[Aname]) {
      window['portal_form_error'] = {
        ...window['portal_form_error'],
        [name]: true
      }
      return '不能大于可用授权或0和负数'
    } else {
      window['portal_form_error'] = {
        ...window['portal_form_error'],
        [name]: false
      }
    }
  }

}


const DetailGrant = ({ item, name }) => {

  const [currentLicense, setCurrentLicense] = React.useState(null)
  // const proGrant = item?.grant
  React.useEffect(() => {
    api({ name: 'project' }).query({ "skip": 0, "limit": 15, fields: ['grant', 'license'] }, { where: { 'id': item.id } })
      .then(({ items }) => {
        setCurrentLicense(items && items[0] && items[0].license)
      })
  }, [])

  if (!item && item.grant) return null
  return <Card style={{ border: '1px solid #d9d9d9', minHeight: 220 }}>
    <Row>
      <Col flex={6}>
        <Divider style={{ margin: 0 }}>{name ? name : '项目授权'}</Divider>
        <div style={{ margin: '0 auto', textAlign: 'center', fontSize: '15px' }}>
          <Row style={{ lineHeight: '40px' }}>
            <Col flex={2} >开始时间</Col>
            <Col flex={3}>{item?.grant?.startTime} </Col>
          </Row>
          <Row style={{ lineHeight: '40px' }}>
            <Col flex={2}>时长</Col>
            <Col flex={3}>{item?.grant?.duration} 天</Col>
          </Row>
          <Row style={{ lineHeight: '40px' }}>
            <Col flex={2}>用户</Col>
            <Col flex={3}>{item?.grant?.userCount} 名</Col>
          </Row>
          <Row style={{ lineHeight: '40px' }}>
            <Col flex={2}>点数</Col>
            <Col flex={3}>{item?.grant?.point} 点</Col>
          </Row>
        </div>
      </Col>
      {
        !name && <Col flex={6}>
          <Divider style={{ margin: 0 }}>项目剩余授权</Divider>
          <div style={{ margin: '0 auto', textAlign: 'center', fontSize: '15px' }}>
            {/* <Row style={{ lineHeight: '40px' }}>
              <Col flex={2} >结束时间</Col>
              <Col flex={3}>{moment(proGrant.startTime).add(proGrant.duration, 'd').format('YYYY-MM-DD')}</Col>
            </Row> */}
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>时长</Col>
              <Col flex={3}>{currentLicense?.days} 天</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>用户</Col>
              <Col flex={3}>{currentLicense?.userCounts} 名</Col>
            </Row>
            <Row style={{ lineHeight: '40px' }}>
              <Col flex={2}>点数</Col>
              <Col flex={3}>{currentLicense?.pointCounts} 点</Col>
            </Row>
          </div>
        </Col>
      }

    </Row>
  </Card>
}

export { DetailGrant, GrantComponent }