/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { use, api } from 'xadmin'
import moment from 'moment'
import { message } from 'antd'
import _ from 'lodash';
import { SettingContext } from './context'


const useGetAuthorization = (userId = '') => {
  const [authData, setAuthData] = React.useState({})
  const { user } = use('redux', state => ({ user: state.user }))

  const getUserData = (userData, json) => {
    const ownProject = json && json.filter(item => item.user === user.username)
    const templeteProject = json.filter(item => item?.projectType == '选择方案模板')
    const totalNum = json.length;
    const platformNum = ownProject.length;
    const tenantNum = totalNum - platformNum
    const disableNum = json.filter(item => !item.status)
    const templeteNum = templeteProject.length
    let userJson = _.cloneDeep(userData)
    let totalPoint = 0;
    let totalUserCount = 0;
    json && json.forEach(item => {
      if (item?.grant?.point) totalPoint = item.grant.point += totalPoint
      if (item?.grant?.userCount) totalUserCount = item.grant.userCount += totalUserCount

    })
    if (userJson?.grant) {
      const { startTime, duration, point, userCount } = userJson.grant
      const effectivePoints = point - totalPoint
      const effectiveUser = userCount - totalUserCount
      const deadline = moment(startTime).add(duration, 'd').format('YYYY-MM-DD HH:mm:ss')
      const expectDate = moment(startTime).add(duration, 'days').valueOf()
      const endDate = moment(startTime).add(duration, 'days')
      const days = parseInt(((expectDate - moment(new Date()).valueOf()) / (1000 * 3600 * 24)))
      setAuthData({ effectivePoints, effectiveUser, endDate, deadline, days, tenantNum, disableNum, totalNum, platformNum, templeteNum, userData })
    } else {
      setAuthData({ tenantNum, disableNum, totalNum, platformNum, templeteNum, userData })
    }
  }

  useEffect(() => {
    if (JSON.stringify(userId) != '{}') {
      api({ name: 'user' }).query({ "skip": 0, "limit": 15, fields: ['grant'] }, { where: { 'id': userId } })
        .then(({ items: userData }) => {
          api({ name: 'project' }).query({ "skip": 0, "limit": 150000000, fields: ['grant'] }, { where: { 'user': userId } })
            .then(({ items, status }) => {
              getUserData(userData[0], items)
            })
        })
    } else {
      api({ name: 'login' }).fetch(`/current/user`, {})
        .then(({ json: userData }) => {
          api({ name: '' }).fetch('project', {})
            .then(({ json, status }) => {
              if (status !== 200 || !json) return false
              getUserData(userData, json)
            })
        })
        .catch(err => err?.message && message.error(err?.message))
    }
  }, [])

  return authData
}

const useGetUserLicense = () => {
  const [data, setData] = React.useState({})

  useEffect(() => {
    api({ name: 'core' }).fetch('/license/info', {}).then(({ json }) => {
      json && setData(json)
    })
  }, [])
  return data
}
const useGetCurrentGrant= () => {
  const [data, setData] = React.useState(0)
  const query = {
    "sort": { "createTime": -1 },
    "skip": 0,
    "limit": 9999,
    "project": {
      "grant.point": 1
    }, "withCount": true
  }
  useEffect(() => {
    api({ name: '' }).fetch('/user?query=' + encodeURIComponent(JSON.stringify(query)), {}).then(({ json }) => {
      json && setData(json.reduce((prev, next) => (prev?.grant?.point || prev) + next?.grant?.point))
    })
  }, [])
  return data
}
const useGetAdminProjectGrant = () => {
  const [data, setData] = React.useState(0)
  const query = {
    "sort": { "createTime": -1 },
    "skip": 0,
    "limit": 9999,
    "project": {
      "grant.point": 1
    },
    "filter": { "user": "admin" }, "withCount": true
  }
  useEffect(() => {
    api({ name: '' }).fetch('/project?query=' + encodeURIComponent(JSON.stringify(query)), {}).then(({ json }) => {
      json && setData(json.reduce((prev, next) => (prev?.grant?.point || prev) + next?.grant?.point))
    })
  }, [])
  return data
}

const getSettings = () => {
  const [settings, setSettings] = React.useState({})

  React.useEffect(() => {
    api({ name: 'setting' }).fetch('', {})
      .then(({ json }) => {
        setSettings(json)
        console.log(json)
      })
  }, [])

  const saveSettings = (data) => {
    return api({ name: 'setting' }).fetch('', {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(({ json }) => {
        return json
      })
  }

  return { settings, saveSettings }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'getAuthorization': useGetAuthorization,
  'getUserLicense': useGetUserLicense,
  'getCurrentGrant': useGetCurrentGrant,
  'getAdminProjectGrant': useGetAdminProjectGrant,
  // 'settings': getSettings,
  'settings': () => React.useContext(SettingContext),
  'settings.context': props => {
    const [init, setInit] = React.useState(false)
    const [settings, setSettings] = React.useState({})

    React.useEffect(() => {
      getSettings()
      setTimeout(() => setInit(true), 0)
    }, [])

    const saveSettings = async (value) => {
      try {
        api({ name: 'setting' }).fetch('',
          {
            method: value?.name ? 'PATCH' : 'PUT',
            body: JSON.stringify(value)
          }
        ).then(({ json }) => {
          setSettings(value)
          message.success('系统设置保存成功')
        })
      }
      catch (error) {
        console.error(error)
      }
    }

    const getSettings = async () => {
      const settings = await api({ name: 'setting' })
        .fetch('').then(({ json }) => json)
      setSettings(settings)
      return { settings }
    }

    return { ...props, show: init, settings, getSettings, saveSettings }
  }
}
