/* eslint-disable no-useless-concat */
import React from 'react'
import moment from 'moment'
import { app, use, api } from 'xadmin'
import { Button, Card, Empty, Tooltip, Radio, message, Input, Tag } from 'antd'
import { DetailGrant, GrantComponent } from './../components/GrantComponent'
import { ActionBanStart, ActionDelete } from './../components/ActionButton'
import ProjectModal from './../components/ProjectModal'
import { ProjectOutlined } from '@ant-design/icons'
import ProjectPage from './../components/ProjectPage'
import Nodata from './../components/Nodata'

import { MinusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const user = JSON.parse(localStorage.getItem('user'))
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Project: {
    name: 'Project',
    resource: 'project',
    type: 'object',
    icon: <ProjectOutlined />, // fa-icon
    title: '项目',
    persistent: true,
    permission: {
      add: true,
      edit: true,
      view: true,
      delete: true,
    },
    properties: {
      name: {
        type: 'string',
        title: '项目名称'
      },
      status: {
        type: 'boolean',
        title: '项目状态',
        width: 80,
        boolLabel: ['禁用', '启用']
      },
      projectType: {
        type: 'string',
        title: '项目类型',
        // description: '选定不同类型，可以自定义空白项目或者安装方案模板'
        // enum: ['创建空白项目', '选择方案模板']
      },
      type: {
        type: 'string',
        title: '方案资源库',
        showInList: false,
      },
      user: {
        type: 'string',
        title: '项目用户',
      },
      createTime: {
        type: 'string',
        title: '创建时间',
      },
      industry: {
        type: 'string',
        title: '项目行业',
        enum: ['渔牧业', '智慧城市', '煤炭行业', '制药业', '油田', '供热供暖', '其他']
      },
      grant: {
        type: 'object',
        title: '授权',
        properties: {},
        showInList: false,
        description: user?.isAdmin ? '定义项目可用的授权信息，包括有效时长、可用点数、可用用户数' : '在租户授权范围内定义项目可用的授权信息，包括有效时长、可用点数、可用用户数'
      },
      license: {
        type: 'object',
        title: '项目授权',
        properties: {},
        showInList: false
      },
      remarks: {
        type: 'string',
        title: '描述',
        fieldType: 'textarea'
      },
      bgColor: {
        title: '背景色',
        type: 'string',
        fieldType: 'color_picker',
        description: '用于设定项目管理卡片展示时，卡片的背景色',
      }
    },
    filters: {
      navform: [
        'name',
        {
          key: 'status', boolLabel: ['启用', '禁用']
        },
        {
          key: 'projectType', component: (props) => {
            return <Radio.Group
              {...props}
              {...props.input}
              // options={['空白项目', '模板项目']}
              options={[
                { label: '空白项目', value: '创建空白项目' },
                { label: '模板项目', value: '选择方案模板' },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          }
        }
      ]
    },
    form: [
      {
        key: 'projectType', component: (props) => {
          const isEdit = window.location.hash.includes('edit')
          return <Radio.Group 
            {...props}
            {...props.input}
            onChange={!isEdit? props.input.onChange: null}
            options={['创建空白项目', '选择方案模板']}
            optionType="button"
            buttonStyle="solid"
          />
        }
      },
      'name',
      'industry',
      {
        key: 'grant', component: props => <GrantComponent {...props} type="project" />, validate: (value, s) => {
          const formError = window?.portal_form_error
          const formErr = formError && Object.keys(formError).every(key => formError[key] === false)
          return !formErr && '请检查可用授权'
        },
      },
      { key: 'type', component: ProjectModal },
      { key: 'remarks', type: 'textarea' }, 'bgColor'
    ],
    defaultPageSize: 24,
    batchActions: null,
    orders: { createTime: 'DESC' },
    required: ['name', 'industry', 'grant', 'projectType'],
    forceGetItem: true,
    projectFields: ['grant', 'license', 'status', 'projectType'],
    formEffect: form => {
      setTimeout(() => {
        form.useField('projectType', state => {
          if (state.value === '创建空白项目') {
            form.setFieldData('type', { display: false, })
            form.change('type', undefined)
          } else if (state.value === '选择方案模板') {
            form.setFieldData('type', { display: true, required: true })
          } else {
            form.setFieldData('type', { display: false })
            form.change('projectType', '创建空白项目')
          }
        })
      })
    },
    fieldRender: {
      user: ({ item, wrap: Wrap }) => (
        <Wrap> {item?.user?.name} </Wrap>
      ),
      bgColor: ({ item, wrap: Wrap }) => (
        <Wrap> <div style={{ background: item?.bgColor, width: '100px', height: '30px' }}></div> </Wrap>
      ),
      createTime: ({ item, wrap: Wrap }) => (
        <Wrap> {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')} </Wrap>
      ),
      grant: DetailGrant,
      status: ({ item, wrap: Wrap }) => {
        return <Wrap>{item.status ? <Tag icon={<CheckCircleOutlined />} color="success">已启用</Tag> : <Tag icon={<MinusCircleOutlined />} color="default">已禁用</Tag>} </Wrap>
      }
    },
    components: {
      ListPage: ProjectPage,
      DataEmpty: props => <Card><Nodata /></Card>,
    },
    listFields: ['name', 'user', 'status', 'createTime', 'remarks', 'bgColor'],
    itemActions: [
      ({ item }) => (<Tooltip title="跳转前台"><Button onClick={() => window.open(`/_p_${item.id}`)}>前台</Button></Tooltip >),
      ({ item }) => (<Tooltip title="跳转后台"><Button onClick={() => window.open(`/admin/_p_${item.id}`)}>后台</Button></Tooltip>),
      ({ item }) => (<Button onClick={() => app.go(`/app/model/Project/${item.id}/detail`)} >详情</Button>),
      ({ item }) => (<ActionBanStart {...item} url="project" />),
      'edit',
      ({ item }) => (<ActionDelete {...item} mes='数据会被永久删除，无法恢复。确认删除该项目？' />),
    ],
    formProps: {
      onSubmit: async (value) => {
        if (value.projectType == '选择方案模板' && !value.type) {
          message.error('请选择方案资源库后再进行提交！')
          return
        }
        if (value.type && !value.id) {
          let tableName = ['model', 'node', 'report', 'table', 'dashboard']
          let download_url = await api({ name: 'templates' }).fetch(``, {})
            .then(({ json }) => json.filter(ele => ele.id == value.type))
          download_url = download_url[0].download_url
          let obj = {
            projectId: value.id,
            collections: tableName,
            url: download_url
          }
          await api({ name: '' }).fetch('project/_template', {
            method: 'POST',
            body: JSON.stringify({ ...value, ...obj })
          }).then((respons) => {
            message.success('添加项目成功！')
          }).catch(err => {
            err?.message && message.error(err.message)
          })
        } else if (!value.id) {
          await api({ name: '' }).fetch('project', {
            method: 'POST',
            body: JSON.stringify(value)
          }).then(({ state, json }) => {
            message.success('添加项目成功！')
          }).catch(err => {
            err?.message && message.error(err.message)
          })
        } else {
          await api({ name: '' }).fetch('project/' + value.id, {
            method: 'PUT',
            body: JSON.stringify(value)
          }).then(({ state, json }) => {
            message.success('修改项目成功！')
          }).catch(err => {
            err?.message && message.error(err.message)
          })
        }
      },
      onSubmitSuccess: async (value, form) => {
        if (value.projectType == '选择方案模板' && !value.type) {
          return
        }
        app.go('/app/model/Project/list')
      }
    },
    // blocks: {
    //   'model.list.nav': SearchBar
    // }
  }
}






